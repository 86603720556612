import { setSessionId } from '@amplitude/analytics-browser';
import {
  AuthService,
  CommutingTripService,
  Employee,
  EmployeeService,
  GeogroupService,
  HttpService,
  Partner,
  StripeService,
  UserService,
  i18nCommons,
  setLocales,
} from '@geovelo-frontends/commons';
import {
  ReactNode,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { environment } from '../../environment';
import useEmployees from '../../hooks/employees';
import LoadingPage from '../../pages/loading';
import { AppContext, offersMap } from '../context';
import { languages } from '../i18n';

import routes, { TPaths, defaultGuestPath, defaultPrivatePath } from './routes';

export type TRouterRef = { selectCompany: (employee: Employee, partner: Partner) => void };

function Router(
  {
    cancellablePromise,
    cancelPromises,
  }: {
    cancelPromises: () => void;
    cancellablePromise: <T>(p: Promise<T>) => Promise<T>;
  },
  ref: Ref<TRouterRef>,
): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    history,
    user: { current: currentUser, employee, home: userHome, works: userWorks },
    actions: {
      setStripeProducts,
      setCurrentPartner,
      setPartnerContract,
      setPartnerInvitationCode,
      setPartnerGeogroup,
      setPartnerTeams,
      setPartnerSites,
      setPartnerEmployeesCount,
      setPartnerInactiveEmployees,
      setPartnerFMDConfig,
      setPartnerMobilitySurveys,
      setPartnerPermissions,
      setPartnerHasChallenges,
      setPartnerAvailableEvents,
      setPartnerGetStartedSkippedSteps,
      setCurrentUser,
      setUserIsRegister,
      setUserEmployee,
      setUserEmployees,
      setUserTeam,
      setUserSite,
      setUserHome,
      setUserWorks,
      setUserReferenceTrips,
      setUserCommutingTrips,
      setUserTransportHabits,
    },
  } = useContext(AppContext);
  const { i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isWebview, setWebview] = useState(pathname.indexOf('/webviews') === 0);
  const [isConnect, setConnect] = useState(pathname.indexOf('/connect') > 0);
  const [isOnboardingWebview, setOnboardingWebview] = useState(
    pathname.indexOf('/webviews/onboarding') === 0,
  );
  const [isAddCompanyWebview, setAddCompanyWebview] = useState(
    pathname.indexOf('/webviews/add-companny') === 0,
  );
  const navigate = useNavigate();
  const { selectEmployee } = useEmployees({
    isWebview,
    isOnboardingWebview,
    isAddCompanyWebview,
    cancellablePromise,
  });

  async function selectCompany(employee: Employee, partner: Partner) {
    setUserEmployee(undefined);
    setUserTeam(undefined);
    setUserSite(undefined);
    setUserTransportHabits(undefined);
    setCurrentPartner(undefined);
    setPartnerContract(undefined);
    setPartnerInvitationCode(undefined);
    setPartnerGeogroup(undefined);
    setPartnerTeams(undefined);
    setPartnerSites(undefined);
    setPartnerEmployeesCount(undefined);
    setPartnerInactiveEmployees(undefined);
    setPartnerFMDConfig(undefined);
    setPartnerMobilitySurveys(undefined);
    setPartnerPermissions(offersMap['geovelo-entreprise-free'].permissions);
    setPartnerHasChallenges(undefined);
    setPartnerAvailableEvents(undefined);
    setPartnerGetStartedSkippedSteps({});

    try {
      await selectEmployee(employee, partner);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  useImperativeHandle(ref, () => ({ selectCompany }));

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (!isWebview) {
        const userId = searchParams.get('user-id');
        const authorizationToken = searchParams.get('auth-token');

        searchParams.delete('user-id');
        searchParams.delete('auth-token');

        setSearchParams(searchParams);

        if (userId && authorizationToken) {
          try {
            HttpService.authorizationToken = `Token ${authorizationToken}`;
            localStorage.setItem('authorization_token', `Token ${authorizationToken}`);
            localStorage.setItem('user_id', `${userId}`);
          } catch {
            console.error('localStorage access is denied');
          }
        }

        getStripeProducts();
        initUser();
      }

      try {
        const _sessionId = searchParams.get('session-id');
        if (_sessionId) {
          const sessionId = parseInt(_sessionId);
          if (!Number.isNaN(sessionId)) {
            setSessionId(sessionId);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [initialized]);

  useEffect(() => {
    if (
      history.current &&
      (history.current.length === 0 ||
        history.current[history.current.length - 1].localeCompare(pathname) !== 0)
    ) {
      history.current.push(pathname);
    }

    setWebview(pathname.indexOf('/webviews') === 0);
    setConnect(pathname.indexOf('/connect') > 0);
    setOnboardingWebview(pathname.indexOf('/webviews/onboarding') === 0);
    setAddCompanyWebview(pathname.indexOf('/webviews/add-company') === 0);
  }, [pathname]);

  useEffect(() => {
    i18nCommons.changeLanguage(i18n.language);
    setLocales(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (currentUser) {
      const {
        parameters: { language: userLanguage },
      } = currentUser;
      if (i18n.language !== userLanguage && languages[userLanguage]) {
        i18n.changeLanguage(userLanguage);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!initialized) return;

    if (currentUser) {
      getEmployeeAndPartner();
      getUserPlaces();
    } else if (currentUser === null) {
      setUserHome(null);
      setUserWorks([]);
      setUserReferenceTrips([]);
      setUserCommutingTrips([]);
      setPartnerContract(null);
      setPartnerInvitationCode(null);
      setPartnerGeogroup(null);
      setPartnerTeams(null);
      setPartnerSites(null);
      setPartnerEmployeesCount(0);
      setPartnerInactiveEmployees(null);
      setPartnerFMDConfig(null);
      setPartnerMobilitySurveys([]);
      setPartnerPermissions(offersMap['geovelo-entreprise-free'].permissions);
      setPartnerHasChallenges(false);
      setPartnerAvailableEvents(null);
    }

    return () => {
      cancelPromises();
      setUserEmployee(undefined);
      setUserEmployees(undefined);
      setUserIsRegister(undefined);
      setUserTeam(undefined);
      setUserSite(undefined);
      setUserHome(undefined);
      setUserWorks(undefined);
      setUserTransportHabits(undefined);
      setCurrentPartner(undefined);
      setPartnerContract(undefined);
      setPartnerInvitationCode(undefined);
      setPartnerGeogroup(undefined);
      setPartnerTeams(undefined);
      setPartnerSites(undefined);
      setPartnerEmployeesCount(undefined);
      setPartnerInactiveEmployees(undefined);
      setPartnerFMDConfig(undefined);
      setPartnerMobilitySurveys(undefined);
      setPartnerPermissions(offersMap['geovelo-entreprise-free'].permissions);
      setPartnerHasChallenges(undefined);
      setPartnerAvailableEvents(undefined);
      setPartnerGetStartedSkippedSteps({});
    };
  }, [initialized, currentUser]);

  useEffect(() => {
    if (!initialized || environment.enableMultimodalJourneys) return;

    if (userWorks && userHome && !isOnboardingWebview && !isAddCompanyWebview) {
      getUserReferenceTrips();
    }

    return () => {
      setUserReferenceTrips(undefined);
    };
  }, [initialized, userHome, userWorks]);

  useEffect(() => {
    if (!initialized) return;

    if (
      environment.enableMultimodalJourneys &&
      employee &&
      userWorks &&
      userHome &&
      !isOnboardingWebview &&
      !isAddCompanyWebview
    ) {
      getUserCommutingTrips(employee.id);
    }

    return () => {
      setUserCommutingTrips(undefined);
    };
  }, [initialized, employee, userHome, userWorks]);

  async function signOut() {
    await AuthService.signOut();
    setCurrentUser(null);
  }

  async function initUser() {
    try {
      const user = await UserService.getCurrentUser();

      setCurrentUser(user);
      if (user) setUserIsRegister(false);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function getStripeProducts() {
    try {
      const products = await StripeService.getProducts({
        contractCodes: ['geovelo-entreprise-premium', 'geovelo-entreprise-standard'],
      });

      const prices = await Promise.all(
        products.map(({ id }) => StripeService.getPrices({ productId: id })),
      );

      setStripeProducts([
        ...products.map(({ ...product }, index) => ({ ...product, prices: prices[index] })),
        {
          id: 'free',
          contractCode: 'geovelo-entreprise-free',
          description: 'Bases de Geovelo Entreprise',
          descriptionLong: "Votre niveau d'offre actuel, avec les challenges automatiques",
          features: [
            'Gestion des salariés',
            'Challenges mensuels automatiques',
            "Création d'actualités",
            'Statistiques globales',
            'Enquête mobilité de base',
          ],
          prices: [
            { id: 'free-month-100', price: 0, periodType: 'month', companyMaxSize: 100 },
            { id: 'free-month-500', price: 0, periodType: 'month', companyMaxSize: 500 },
            { id: 'free-year-100', price: 0, periodType: 'year', companyMaxSize: 100 },
            { id: 'free-year-500', price: 0, periodType: 'year', companyMaxSize: 500 },
          ],
        },
      ]);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserPlaces() {
    try {
      const places = await cancellablePromise(UserService.getPlaces());
      setUserHome(places.find(({ type }) => type === 'home') || null);
      setUserWorks(places.filter(({ type }) => type === 'work'));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserHome(null);
        setUserWorks([]);
      }
    }
  }

  async function getUserReferenceTrips() {
    try {
      const { referenceTrips } = await cancellablePromise(
        UserService.getReferenceTrips({
          page: 1,
          pageSize: 100,
          query:
            '{id, created, title, geo_start, geo_start_title, geo_end, geo_end_title, distance_in_meters_start_end, distance_in_meters_end_start, enabled}',
        }),
      );

      setUserReferenceTrips(referenceTrips);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserReferenceTrips([]);
      }
    }
  }

  async function getUserCommutingTrips(employeeId: number) {
    try {
      const trips = await cancellablePromise(
        CommutingTripService.getCommutingTrips({ employeeId }),
      );

      setUserCommutingTrips(trips);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserCommutingTrips([]);
      }
    }
  }

  async function getEmployeeAndPartner() {
    if (state?.isSubscription || isConnect || isAddCompanyWebview) return;

    const searchParam = searchParams.get('partner-id');
    let localStorageItem: string | null = null;

    try {
      localStorageItem = localStorage.getItem('company_id');
    } catch {
      console.error('localStorage access is denied');
    }

    const companyId = searchParam
      ? parseInt(searchParam)
      : localStorageItem
        ? parseInt(localStorageItem)
        : null;

    try {
      let employees = await cancellablePromise(EmployeeService.getEmployees());
      let employee: Employee | null;
      let _partner: Partner | null;
      ({ employee, partner: _partner } =
        (companyId &&
          !Number.isNaN(companyId) &&
          employees.find(({ partner }) => partner.id === companyId)) ||
        employees.find(({ employee }) => employee.status === 'EMPLOYEE_JOIN') ||
        employees[0] ||
        {});

      if (!employee && isOnboardingWebview) {
        const userGeogroups = await GeogroupService.getUserGeogroups();
        const geogroup = userGeogroups.find(
          ({ status, group }) =>
            ['automaticallyJoin', 'manuallyJoin'].includes(status) && group.type === 'company',
        )?.group;
        if (geogroup?.partner) {
          const _userId = searchParams.get('user-id');
          const userId = parseInt(_userId || '');

          if (!Number.isNaN(userId)) {
            const { code: invitationCode } = await GeogroupService.getInvitationLink(geogroup.id);
            await EmployeeService.createEmployee(geogroup.partner, {
              userId,
              invitationCode,
            });
            employees = await cancellablePromise(EmployeeService.getEmployees());
            ({ employee, partner: _partner } = employees[0] || {});
          }
        }
      }

      if (
        (!employee || employee.status === 'EMPLOYEE_WAITING_FOR_APPROVAL' || !_partner) &&
        !isOnboardingWebview
      ) {
        if (!employee || employee.status === 'EMPLOYEE_WAITING_FOR_APPROVAL') {
          setUserEmployee(null);
          setUserEmployees([]);
          navigate('/welcome/not-linked', {
            state: { allowNotLinkedEmployee: true, partner: employee?.partner },
          });
        } else {
          signOut();
        }

        return;
      }

      await selectEmployee(employee, _partner);

      setUserEmployees(employees.filter(({ employee }) => employee.status === 'EMPLOYEE_JOIN'));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        if (!isOnboardingWebview && !isAddCompanyWebview) signOut();
      }
    }
  }

  return (
    <Routes>
      {routes.map(({ isPrivate, isGuest, element, ...otherProps }) => {
        if (isPrivate)
          return (
            <Route
              element={<PrivateRoute path={otherProps.path}>{element}</PrivateRoute>}
              key={otherProps.path || 'index'}
              {...otherProps}
            />
          );
        if (isGuest)
          return (
            <Route
              element={<GuestRoute>{element}</GuestRoute>}
              key={otherProps.path || 'index'}
              {...otherProps}
            />
          );

        return <Route element={element} key={otherProps.path || 'index'} {...otherProps} />;
      })}
      <Route element={<Navigate to="/" />} path="*" />
    </Routes>
  );
}

function GuestRoute({ children }: { children: ReactNode }): JSX.Element {
  const { state } = useLocation();
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  if (currentUser && !state?.allowNotLinkedEmployee)
    return <Navigate to={state?.requestedPath || defaultPrivatePath} />;

  return <>{children}</>;
}

function PrivateRouteContent({ children }: { children: ReactNode }): JSX.Element {
  return <>{children}</>;
}

function PrivateRoute({
  path,
  children,
}: {
  children: ReactNode;
  path?: TPaths | '*';
}): JSX.Element {
  const {
    user: { current: currentUser, employee, transportHabits },
    partner: { current: currentPartner, geogroup: currentPartnerGeogroup },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { pathname, search, state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.document.getElementsByTagName('main')[0]?.scrollTo(0, 0);
  }, [pathname]);

  if (state?.isSubscription) return <PrivateRouteContent>{children}</PrivateRouteContent>;

  if (currentUser === null)
    return <Navigate state={{ requestedPath: `${pathname}${search}` }} to={defaultGuestPath} />;

  if (employee === null)
    return state?.allowNotLinkedEmployee ? (
      <PrivateRouteContent>{children}</PrivateRouteContent>
    ) : (
      <></>
    );

  if (
    currentUser === undefined ||
    employee === undefined ||
    transportHabits === undefined ||
    (currentUser && (currentPartner === undefined || currentPartnerGeogroup === undefined))
  )
    return <LoadingPage text={t('commons.user.loading')} />;

  if (pathname !== `/${path}`) {
    if (
      !employee.firstName ||
      !employee.lastName ||
      !employee.eulaAccepted ||
      !employee.privacyPolicyAccepted
    ) {
      return <Navigate to="/onboarding/personal-data" />;
    }

    if (!transportHabits) {
      return <Navigate to="/onboarding/transport-modes" />;
    }
  }

  return <PrivateRouteContent>{children}</PrivateRouteContent>;
}

export default forwardRef(Router);
