"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaTypes = exports.difficulties = exports.areas = exports.RideService = void 0;
exports.parseRideMedia = parseRideMedia;
exports.rideThemeKeys = exports.publicKinds = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _app = require("./app");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
var _photo = require("./photo");
var _poi = require("./poi");
var _publicationStatus = require("./publication-status");
var _rideTrace = require("./ride-trace");
var _route = require("./route");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class RideService {
  static async addRideV1(_ref) {
    let {
      title
    } = _ref;
    try {
      const data = await _http.HttpService.post('v1', `/rides`, [], [], JSON.stringify({
        title
      }));
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][addRideV1]', err);
      throw err;
    }
  }
  static async addRide(_ref2) {
    let {
      title,
      computedRoute,
      description,
      privacy,
      bikeType,
      profile
    } = _ref2;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const route = await _route.RouteService.saveRoute({
        type: 'RIDE',
        computedRouteId: computedRoute.id
      });
      const {
        wayPoints
      } = route;
      const departure = wayPoints[0];
      const arrival = wayPoints[wayPoints.length - 1];
      const props = {
        title,
        route_atob: route.id,
        geo_point_a: departure?.point,
        geo_point_a_title: departure?.addressDetail?.city,
        geo_point_b: arrival?.point,
        geo_point_b_title: arrival?.addressDetail?.city,
        is_loop: departure?.strPoint === arrival?.strPoint,
        description,
        privacy: privacy === 'private' ? 'PRIVATE' : 'PUBLIC',
        bike_type: _route.bikeTypesMap[bikeType],
        profile: _cyclingProfile.cyclingProfilesMap[profile],
        publication_status: 'PUBLISHED'
      };
      const data = await _http.HttpService.post('v2', `/rides`, [], [], JSON.stringify(props));
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][addRide]', err);
      throw err;
    }
  }
  static async addRideStep(rideId, props) {
    try {
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/steps`, [], [], JSON.stringify(props));
      return parseRideStep(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][addRideStep]', err);
      throw err;
    }
  }
  static async updateRideThemes(rideId, themesIds) {
    try {
      await _http.HttpService.post('v2', `/rides/${rideId}/themes`, [], [], JSON.stringify({
        ride_theme_ids: themesIds
      }));
      return true;
    } catch (err) {
      console.error('[RideService][updateRideThemes]', err);
      throw err;
    }
  }
  static async addRidePhoto(rideId, _ref3) {
    let {
      title,
      description,
      copyright,
      file
    } = _ref3;
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('copyright', copyright);
      formData.append('photo', file);
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/photos`, [], [], formData);
      return (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][addRidePhoto]', err);
      throw err;
    }
  }
  static async addRideMedia(rideId, props) {
    try {
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/medias`, [], [], JSON.stringify(props));
      return parseRideMedia(data);
    } catch (err) {
      console.error('[RideService][addRideMedia]', err);
      throw err;
    }
  }
  static async addTraceToRide(rideId, traceId) {
    try {
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/traces`, [], [], JSON.stringify({
        ride_trace_id: traceId
      }));
      const trace = (0, _rideTrace.parseRideTrace)(data);
      if (!trace) throw new Error('trace has no geometry');
      return trace;
    } catch (err) {
      console.error('[RideService][addRideTrace]', err);
      throw err;
    }
  }
  static async addRideStepPhoto(rideId, stepId, _ref4) {
    let {
      copyright,
      file
    } = _ref4;
    try {
      const formData = new FormData();
      formData.append('copyright', copyright);
      formData.append('photo', file);
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/steps/${stepId}/photos`, [], [], formData);
      return (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][addRideStepPhoto]', err);
      throw err;
    }
  }
  static async addRideStepMedia(rideId, stepId, props) {
    try {
      const data = await _http.HttpService.post('v1', `/rides/${rideId}/steps/${stepId}/medias`, [], [], JSON.stringify(props));
      return parseRideMedia(data);
    } catch (err) {
      console.error('[RideService][addRideStepMedia]', err);
      throw err;
    }
  }
  static async getRideThemes() {
    try {
      const data = await _http.HttpService.get('v1', '/ride_themes');
      return data.map(parseRideTheme);
    } catch (err) {
      console.error('[RideService][getRideThemes]', err);
      throw err;
    }
  }
  static async getRides(_ref5) {
    let {
      page,
      pageSize,
      ordering,
      orderBy,
      order,
      search,
      publicationStatuses,
      ids,
      partnerIds,
      creatorId,
      themes,
      noTheme,
      difficulties,
      closeTo,
      maxDistance,
      bounds,
      query,
      edition
    } = _ref5;
    try {
      const params = [];
      if (partnerIds && partnerIds.length > 0) params.push({
        key: 'partners',
        value: partnerIds.join(',')
      });
      if (creatorId) params.push({
        key: 'creator',
        value: creatorId
      });
      if (ordering) {
        params.push({
          key: 'ordering',
          value: ordering
        });
      } else if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';else if (orderBy === 'title') value = 'title';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (search) params.push({
        key: 'search',
        value: search
      });
      if (publicationStatuses) params.push({
        key: 'publication_status',
        value: publicationStatuses.join(',')
      });
      if (ids) params.push({
        key: 'id',
        value: ids.join(',')
      });
      if (themes) params.push({
        key: 'themes',
        value: themes.join(',')
      });else if (noTheme) params.push({
        key: 'no_theme',
        value: true
      });
      if (difficulties) {
        const backendDifficulties = {
          easy: 'EASY',
          medium: 'MEDIUM',
          hard: 'HARD'
        };
        params.push({
          key: 'difficulty',
          value: difficulties.map(key => backendDifficulties[key]).join(',')
        });
      }
      if (maxDistance) params.push({
        key: 'max_distance',
        value: maxDistance
      });
      if (closeTo) params.push({
        key: 'close_to',
        value: closeTo.coordinates[0] + ',' + closeTo.coordinates[1]
      });
      if (bounds) {
        const {
          north,
          east,
          south,
          west
        } = bounds;
        params.push({
          key: 'north',
          value: north
        }, {
          key: 'east',
          value: east
        }, {
          key: 'south',
          value: south
        }, {
          key: 'west',
          value: west
        });
      }
      if (edition) params.push({
        key: 'edition',
        value: true
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v2', '/rides', [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }, ...params]);
      return {
        count,
        next,
        rides: results.map(data => parseRide(data, _app.AppService.environment.backendUrl))
      };
    } catch (err) {
      console.error('[RideService][getRides]', err);
      throw err;
    }
  }
  static async getRide(id) {
    try {
      const data = await _http.HttpService.get('v2', `/rides/${id}`);
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][getRide]', err);
      throw err;
    }
  }
  static async updateRideV1(id, _props) {
    const props = {
      ..._props
    };
    for (const key in props) {
      if (typeof key === 'undefined') delete props[key];
    }
    try {
      const data = await _http.HttpService.put('v1', `/rides/${id}`, [], [], JSON.stringify(props));
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][updateRideV1]', err);
      throw err;
    }
  }
  static async updateRide(id, _ref6) {
    let {
      title,
      description,
      privacy,
      route,
      bikeType,
      profile
    } = _ref6;
    let props = {};
    if (title) props.title = title;
    if (description !== undefined) props.description = description;
    if (privacy) props.privacy = privacy === 'private' ? 'PRIVATE' : 'PUBLIC';
    if (route) {
      const {
        wayPoints
      } = route;
      props = {
        ...props,
        route_atob: route.id,
        geo_point_a: wayPoints[0].point,
        geo_point_b: wayPoints[wayPoints.length - 1].point
      };
    }
    if (bikeType) props.bike_type = _route.bikeTypesMap[bikeType];
    if (profile) props.profile = _cyclingProfile.cyclingProfilesMap[profile];
    try {
      const data = await _http.HttpService.put('v2', `/rides/${id}`, [], [], JSON.stringify(props));
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][updateRide]', err);
      throw err;
    }
  }
  static async updateRideIcon(rideId, icon) {
    try {
      const formData = new FormData();
      formData.append('icon', icon);
      const data = await _http.HttpService.put('v1', `/rides/${rideId}/icon`, [], [], formData);
      return parseRide(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][updateRideIcon]', err);
      throw err;
    }
  }
  static async updateRideStep(rideId, stepId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/rides/${rideId}/steps/${stepId}`, [], [], JSON.stringify(props));
      return parseRideStep(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][updateRideStep]', err);
      throw err;
    }
  }
  static async updateRidePhoto(rideId, photoId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/rides/${rideId}/photos/${photoId}`, [], [], JSON.stringify(props));
      return (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[RideService][updateRidePhoto]', err);
      throw err;
    }
  }
  static async updateRideMedia(rideId, mediaId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/rides/${rideId}/medias/${mediaId}`, [], [], JSON.stringify(props));
      return parseRideMedia(data);
    } catch (err) {
      console.error('[RideService][updateRideMedia]', err);
      throw err;
    }
  }
  static async removeRide(id) {
    try {
      await _http.HttpService.delete('v1', `/rides/${id}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRide]', err);
      throw err;
    }
  }
  static async removeRideStep(rideId, stepId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/steps/${stepId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRideStep]', err);
      throw err;
    }
  }
  static async removeRideIcon(rideId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/icon`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRideIcon]', err);
      throw err;
    }
  }
  static async removeRidePhoto(rideId, photoId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/photos/${photoId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRidePhoto]', err);
      throw err;
    }
  }
  static async removeRideMedia(rideId, mediaId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/medias/${mediaId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRideMedia]', err);
      throw err;
    }
  }
  static async removeTraceFromRide(rideId, traceId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/traces/${traceId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeTraceFromRide]', err);
      throw err;
    }
  }
  static async removeRideStepPhoto(rideId, stepId, photoId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/steps/${stepId}/photos/${photoId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRideStepPhoto]', err);
      throw err;
    }
  }
  static async removeRideStepMedia(rideId, stepId, mediaId) {
    try {
      await _http.HttpService.delete('v1', `/rides/${rideId}/steps/${stepId}/medias/${mediaId}`);
      return true;
    } catch (err) {
      console.error('[RideService][removeRideStepMedia]', err);
      throw err;
    }
  }
}
exports.RideService = RideService;
const rideThemeKeys = exports.rideThemeKeys = {
  Nature: 'nature',
  Culturel: 'cultural',
  Sportif: 'sportive',
  Montagne: 'mountain',
  Côtier: 'coastal',
  Urbain: 'urban',
  Famille: 'family',
  Gourmet: 'gourmet'
};
function parseRideTheme(_ref7) {
  let {
    id,
    title: key
  } = _ref7;
  return new _models.RideTheme(id, rideThemeKeys[key]);
}
const difficulties = exports.difficulties = {
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard'
};
const publicKinds = exports.publicKinds = {
  EVERYONE: 'everyone',
  FAMILY: 'family',
  ATHLETIC: 'sportive'
};
const areas = exports.areas = {
  LOCAL: 'local',
  REGIONAL: 'regional',
  NATIONAL: 'national'
};
function parseRideStep(_ref8, backendUrl) {
  let {
    id,
    order,
    title,
    description,
    geo_point: location,
    distance_from_geo_point_a: distanceFromStart,
    photos,
    medias,
    poi
  } = _ref8;
  return {
    id,
    order,
    title,
    description,
    location,
    distanceFromStart,
    photos: (photos || []).map(_ref9 => {
      let {
        title: photoTitle,
        ...data
      } = _ref9;
      return (0, _photo.parsePhoto)({
        title: photoTitle || title,
        ...data
      }, backendUrl);
    }),
    medias: (medias || []).map(parseRideMedia),
    poi: poi ? (0, _poi.parsePoi)(poi, {}, backendUrl) : null
  };
}
const mediaTypes = exports.mediaTypes = {
  AUDIO: 'audio',
  FILE: 'file',
  IMAGE: 'image',
  PDF: 'pdf',
  VIDEO: 'video',
  WEBSITE: 'website'
};
function parseRideMedia(_ref10) {
  let {
    id,
    title,
    description,
    copyright,
    url,
    type
  } = _ref10;
  return {
    id,
    title,
    description,
    copyright,
    url,
    type: type ? mediaTypes[type] : undefined
  };
}
function parseRide(_ref11, backendUrl) {
  let {
    id,
    creator,
    title,
    description,
    distance,
    route_duration,
    duration,
    vertical_gain,
    difficulty,
    public_kind,
    area_wide,
    themes,
    icon,
    geo_point_a,
    geo_point_a_title,
    geo_point_b,
    geo_point_b_title,
    geo_point_center,
    geometry_condensed,
    geometry_condensed_return,
    route_atob,
    route_btoa,
    is_loop,
    is_highlighted,
    steps,
    pois,
    photos,
    medias,
    theme_primary_color,
    theme_secondary_color,
    publication_status,
    partners,
    traces,
    related_rideset,
    updated,
    meta_title,
    meta_description,
    ...props
  } = _ref11;
  return new _models.Ride(id, creator && typeof creator === 'object' ? {
    id: creator.id,
    username: creator.username
  } : creator ? {
    username: creator
  } : undefined, title || '', description || null, distance, route_duration !== undefined ? Math.max(route_duration - route_duration % 900, 900) : undefined, duration, vertical_gain, difficulty ? difficulties[difficulty] : undefined, public_kind ? publicKinds[public_kind] : undefined, area_wide ? areas[area_wide] : undefined, themes || [], icon ? `${backendUrl}${icon}` : null, geo_point_a ? new _models.Place(undefined, geo_point_a, geo_point_a_title ? {
    primaryText: geo_point_a_title
  } : undefined) : geo_point_a, geo_point_b ? new _models.Place(undefined, geo_point_b, geo_point_b_title ? {
    primaryText: geo_point_b_title
  } : undefined) : geo_point_b, geo_point_center, geometry_condensed, geometry_condensed_return, route_atob, route_btoa, is_loop, is_highlighted, (steps || []).map(data => parseRideStep(data, backendUrl)).sort((a, b) => a.order && b.order ? a.order - b.order : 0), pois || [], (photos || []).map(_ref12 => {
    let {
      title: photoTitle,
      ...data
    } = _ref12;
    return (0, _photo.parsePhoto)({
      title: photoTitle || title,
      ...data
    }, backendUrl);
  }).sort((a, b) => a.id && b.id ? a.id - b.id : 0), (medias || []).map(parseRideMedia).sort((a, b) => a.id && b.id ? a.id - b.id : 0), theme_primary_color, theme_secondary_color, publication_status ? _publicationStatus.publicationStatuses[publication_status] : undefined, partners ? partners.map(data => typeof data === 'number' ? {
    id: data
  } : data) : undefined, traces || [], related_rideset ? related_rideset.map(_ref13 => {
    let {
      rideset: id
    } = _ref13;
    return {
      id
    };
  }) : undefined, related_rideset ? related_rideset[0]?.ride_atob_previous : undefined, related_rideset ? related_rideset[0]?.ride_atob_next : undefined, (0, _moment.default)(updated), 'created' in props && props.created ? (0, _moment.default)(props.created) : undefined, 'privacy' in props && props.privacy ? props.privacy === 'FORCE_PRIVATE' ? 'forcePrivate' : props.privacy === 'PUBLIC' ? 'public' : 'private' : undefined, 'bike_type' in props && (props.bike_type === 'ELECTRIC' ? 'hybridBike' : 'traditional') || undefined, 'profile' in props && props.profile ? _cyclingProfile.backendCyclingProfiles[props.profile] : undefined, meta_title || undefined, meta_description || undefined);
}