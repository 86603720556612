"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoiAnnotationService = void 0;
exports.parsePoiAnnotation = parsePoiAnnotation;
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _photo = require("./photo");
var _user = require("./user");
class PoiAnnotationService {
  static async getPoiAnnotations(ids, poiCategories) {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const params = [{
        key: 'page',
        value: 1
      }, {
        key: 'page_size',
        value: 100
      }, {
        key: 'query',
        value: '{id, title, description, geo_point, web, email, phone, url_details, photos, categories, poi_source, poi, external_id}'
      }];
      if (ids.length > 0) params.push({
        key: 'id',
        value: ids.join(',')
      });
      const {
        results
      } = await _http.HttpService.get('v3', `/users/${_user.UserService.currentUser.id}/poi_annotations`, params);
      return results.map(data => parsePoiAnnotation(data, poiCategories, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[PoiAnnotationService][getPoiAnnotations]', err);
      throw err;
    }
  }
  static async create(_ref) {
    let {
      poi,
      poiCategories,
      widgetConfig
    } = _ref;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      let body = {};
      if (!widgetConfig || widgetConfig.code === 'geovelo') body = {
        poi: poi.id,
        poi_source: 'GEOVELO'
      };else {
        let poi_source;
        if (widgetConfig.code === 'lav') poi_source = 'LOIRE_A_VELO';else throw new Error('invalid widget code');
        const {
          externalId: external_id,
          location: {
            point: geo_point
          },
          title,
          description,
          email,
          phone,
          detailsUrl: url_details,
          website: web,
          categoryCodes
        } = poi;
        body = {
          geo_point,
          title,
          description,
          email,
          phone,
          url_details,
          web,
          categories: [...new Set(categoryCodes.map(code => poiCategories.find(poiCategory => poiCategory.code === _models.asGeoveloCategoryCode[code])?.id || -1))],
          poi_source,
          external_id
        };
      }
      const data = await _http.HttpService.post('v3', `/users/${_user.UserService.currentUser.id}/poi_annotations`, [], [], JSON.stringify(body));
      const poiAnnotation = parsePoiAnnotation(data, poiCategories.reduce((res, _ref2) => {
        let {
          id,
          code
        } = _ref2;
        res[id] = code;
        return res;
      }, {}), _app.AppService.environment.backendUrl);
      if (widgetConfig && widgetConfig.code !== 'geovelo') {
        const photos = await Promise.all(poi.photos.map(_ref3 => {
          let {
            title,
            description,
            url,
            copyright
          } = _ref3;
          return _http.HttpService.post('v3', `/users/${_user.UserService.currentUser?.id}/poi_annotations/${poiAnnotation.id}/photos`, [], [], JSON.stringify({
            title,
            description,
            url,
            copyright
          }));
        }));
        poiAnnotation.poi.photos.push(...photos.map(data => (0, _photo.parsePhoto)(data, _app.AppService.environment.backendUrl)));
      }
      return poiAnnotation;
    } catch (err) {
      console.error('[PoiAnnotationService][create]', err);
      throw err;
    }
  }
  static async remove(id) {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v3', `/users/${_user.UserService.currentUser.id}/poi_annotations/${id}`);
      return true;
    } catch (err) {
      console.error('[PoiAnnotationService][remove]', err);
      throw err;
    }
  }
}
exports.PoiAnnotationService = PoiAnnotationService;
const sources = {
  GEOVELO: 'geovelo',
  LOIRE_A_VELO: 'lav'
};
function parsePoiAnnotation(_ref4, poiCategories, backendUrl) {
  let {
    id,
    title,
    description,
    geo_point,
    web,
    email,
    phone,
    url_details,
    categories,
    photos,
    poi_source,
    poi,
    external_id
  } = _ref4;
  const source = sources[poi_source];
  return new _models.PoiAnnotation(id, source, new _models.Poi(poi_source === 'GEOVELO' && poi || -1, title, description, new _models.Place(undefined, geo_point), photos.map(_ref5 => {
    let {
      title: photoTitle,
      ...data
    } = _ref5;
    return (0, _photo.parsePhoto)({
      title: photoTitle || title,
      ...data
    }, poi_source === 'GEOVELO' ? backendUrl : undefined);
  }), web, email, phone, url_details, categories.map(id => poiCategories[id]).filter(code => code), null, false, false, 'published', source, external_id || undefined, poi_source === 'LOIRE_A_VELO'));
}