"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.languageCodes = exports.UserService = void 0;
exports.parseUserReferenceTrip = parseUserReferenceTrip;
exports.parseUserReferenceTripOccurrence = parseUserReferenceTripOccurrence;
exports.toBackendTripDirection = toBackendTripDirection;
exports.toTripDirection = toTripDirection;
exports.userTraceDeletionReasons = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _environment = require("../environment");
var _models = require("../models");
var _trace = require("../models/trace");
var _app = require("./app");
var _badge = require("./badge");
var _commutingTrip = require("./commuting-trip");
var _cyclingProfile = require("./cycling-profile");
var _geogroup = require("./geogroup");
var _http = require("./http");
var _partner = require("./partner");
var _route = require("./route");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class UserService {
  static async getUser(id) {
    try {
      const data = await _http.HttpService.get('v1', `/users/${id}`);
      return parseUser(data);
    } catch (err) {
      console.error('[UserService][getUser]', err);
      return null;
    }
  }
  static async getCurrentUser() {
    let {
      userId: _userId
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let userId = _userId;
    if (!userId) {
      try {
        const userIdLSItem = localStorage.getItem('impersonate_user_id') || localStorage.getItem('user_id') || sessionStorage.getItem('user_id');
        userId = userIdLSItem ? parseInt(userIdLSItem, 10) : null;
      } catch {
        console.error('localStorage access is denied');
      }
      if (!userId) return null;
    }
    this.currentUser = await this.getUser(userId);
    if (!this.currentUser) {
      _http.HttpService.authorizationToken = null;
      try {
        localStorage.removeItem('impersonate_authorization_token');
        localStorage.removeItem('impersonate_user_id');
        localStorage.removeItem('authorization_token');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('authorization_token');
        sessionStorage.removeItem('user_id');
      } catch {
        console.error('localStorage access is denied');
      }
    }
    return this.currentUser || null;
  }
  static async getUserByEmail(email) {
    try {
      const data = await _http.HttpService.get('v1', `/users`, [{
        key: 'email',
        value: email
      }]);
      return data[0] ? parseMinimalUser(data[0]) : null;
    } catch (err) {
      console.error('[UserService][getUserByEmail]', err);
      throw err;
    }
  }
  static async searchUsers(_ref) {
    let {
      page,
      pageSize,
      query,
      search
    } = _ref;
    try {
      const {
        results
      } = await _http.HttpService.get('v2', `/users`, [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'search',
        value: search
      }, {
        key: 'query',
        value: query
      }]);
      return results.map(parseMinimalUser);
    } catch (err) {
      console.error('[UserService][searchUsers]', err);
      throw err;
    }
  }
  static async createUser(email) {
    try {
      const data = await _http.HttpService.post('v1', `/users`, [], [], JSON.stringify({
        email: email
      }));
      return parseMinimalUser(data);
    } catch (err) {
      console.error('[UserService][createUser]', err);
      throw err;
    }
  }
  static async updateUser(_ref2) {
    let {
      profilePicture,
      ...props
    } = _ref2;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const formData = new FormData();
      Object.keys(props).forEach(key => formData.append(key, props[key]));
      if (profilePicture) formData.append('profile_picture', profilePicture);
      const data = await _http.HttpService.put('v1', `/users/${this.currentUser.id}`, [], [], formData);
      return parseUser(data);
    } catch (err) {
      console.error('[UserService][updateUser]', err);
      throw err;
    }
  }
  static async updateUserPassword(props) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.put('v1', `/users/${this.currentUser.id}/password`, [], [], JSON.stringify(props));
      return true;
    } catch (err) {
      console.error('[UserService][updateUserPassword]', err);
      throw err;
    }
  }
  static async deleteUser() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v1', `/users/${this.currentUser.id}`);
      try {
        localStorage.removeItem('impersonate_authorization_token');
        localStorage.removeItem('impersonate_user_id');
        localStorage.removeItem('authorization_token');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('authorization_token');
        sessionStorage.removeItem('user_id');
      } catch {
        console.error('localStorage access is denied');
      }
      _http.HttpService.authorizationToken = null;
      UserService.currentUser = null;
      if ('google' in window) google.accounts.id.disableAutoSelect();
      return true;
    } catch (err) {
      console.error('[UserService][deleteUser]', err);
      throw err;
    }
  }
  static async requestData() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.post('v1', `/users/${this.currentUser.id}/gdpr`);
      return true;
    } catch (err) {
      console.error('[UserService][requestData]', err);
      throw err;
    }
  }
  static async getPartners() {
    let {
      isAdmin,
      isSupport,
      withInProgressContract,
      contractTemplateCodes
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      if (!this.currentUser) throw new Error('user not connected');
      let contractTemplateIds;
      if (contractTemplateCodes) {
        const templates = await _partner.PartnerService.getContractTemplates({
          query: '{id, code, title}'
        });
        contractTemplateIds = templates.filter(_ref3 => {
          let {
            code
          } = _ref3;
          return code && contractTemplateCodes.includes(code);
        }).map(_ref4 => {
          let {
            id
          } = _ref4;
          return id;
        });
      }
      const data = await _http.HttpService.get('v3', `/users/${this.currentUser.id}/partners`);
      const partners = data.filter(_ref5 => {
        let {
          partner,
          permitted_pages
        } = _ref5;
        if (permitted_pages?.length === 1 && permitted_pages[0] === 'CVTC') return false;
        const contracts = partner.contracts?.filter(_ref6 => {
          let {
            contract_template,
            start_datetime,
            end_datetime
          } = _ref6;
          return (!withInProgressContract || (0, _moment.default)().isSameOrAfter((0, _moment.default)(start_datetime).startOf('day')) && (0, _moment.default)().isSameOrBefore((0, _moment.default)(end_datetime).endOf('day'))) && (!contractTemplateIds || typeof contract_template === 'number' && contractTemplateIds.includes(contract_template));
        }) || [];
        if ((withInProgressContract || contractTemplateIds) && contracts.length === 0) return false;
        return true;
      }).reduce((res, partnerData) => {
        const {
          partner: {
            contracts
          },
          permitted_pages
        } = partnerData;
        if (permitted_pages?.length === 1 && permitted_pages[0] === 'CVTC') return res;
        const filteredContracts = contracts?.filter(_ref7 => {
          let {
            contract_template,
            start_datetime,
            end_datetime
          } = _ref7;
          return (!withInProgressContract || (0, _moment.default)().isSameOrAfter((0, _moment.default)(start_datetime).startOf('day')) && (0, _moment.default)().isSameOrBefore((0, _moment.default)(end_datetime).endOf('day'))) && (!contractTemplateIds || typeof contract_template === 'number' && contractTemplateIds.includes(contract_template));
        }) || [];
        if ((withInProgressContract || contractTemplateIds) && filteredContracts.length === 0) return res;
        const partner = (0, _partner.parsePartner)(partnerData, isAdmin, isSupport);
        if (partner) res.push(partner);
        return res;
      }, []);
      return partners;
    } catch (err) {
      console.error('[UserService][getPartners]', err);
      return [];
    }
  }
  static async getUserTracesStats(period) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/stats_traces`, [...(0, _models.periodToParams)(period)]);
      const stats = parseUserTracesStats(data);
      return stats;
    } catch (err) {
      console.error('[UserService][getUserTracesStats]', err);
      throw err;
    }
  }
  static async downloadUserTrace(id) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/traces/${id}`, [], [], null, {
        contentType: 'application/gpx+xml',
        responseType: 'blob'
      });
      return data;
    } catch (err) {
      console.error('[UserService][downloadUserTrace]', err);
      throw err;
    }
  }
  static async removeUserTrace(id) {
    let {
      deletionReason,
      deletionComment
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v4', `/users/${this.currentUser.id}/traces/${id}`, [], [], JSON.stringify({
        deletion_reason: deletionReason || 'UNKNOWN',
        deletion_comment: deletionComment || ''
      }));
      return true;
    } catch (err) {
      console.error('[UserService][removeUserTrace]', err);
      throw err;
    }
  }
  static async sendLogs(logs) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.post('v1', `/users/${this.currentUser.id}/logs`, [], [], JSON.stringify({
        logs,
        user: this.currentUser.id
      }));
      return true;
    } catch (err) {
      console.error('[UserService][sendLogs]', err);
      throw err;
    }
  }
  static async getPlaces() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v1', `/users/${this.currentUser.id}/places`);
      const places = data.map(parseUserPlace);
      return places;
    } catch (err) {
      console.error('[UserService][getPlaces]', err);
      throw err;
    }
  }
  static async addPlace(_ref8) {
    let {
      type,
      point: geo_point,
      title,
      address
    } = _ref8;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const types = {
        home: 'HOME',
        other: 'OTHER',
        work: 'WORK',
        transportModeChange: 'TRANSPORT_MODE_CHANGE'
      };
      const body = {
        type: types[type],
        geo_point,
        address
      };
      if (title) body.title = title;
      const data = await _http.HttpService.post('v1', `/users/${this.currentUser.id}/places`, [], [], JSON.stringify(body));
      return parseUserPlace(data);
    } catch (err) {
      console.error('[UserService][addPlace]', err);
      throw err;
    }
  }
  static async updatePlace(id, _ref9) {
    let {
      point: geo_point,
      title,
      address
    } = _ref9;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const body = {
        geo_point,
        address
      };
      if (title) body.title = title;
      const data = await _http.HttpService.put('v1', `/users/${this.currentUser.id}/places/${id}`, [], [], JSON.stringify(body));
      return parseUserPlace(data);
    } catch (err) {
      console.error('[UserService][updatePlace]', err);
      throw err;
    }
  }
  static async removePlace(id) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v1', `/users/${this.currentUser.id}/places/${id}`);
    } catch (err) {
      console.error('[UserService][removePlace]', err);
      throw err;
    }
  }
  static async joinChallenge(_ref10) {
    let {
      geogroupId,
      challengeId
    } = _ref10;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v2', `/users/${this.currentUser.id}/geogroups/${geogroupId}/challenges`, [], [], JSON.stringify({
        challenge: challengeId
      }));
      const userChallenge = parseUserChallenge(data, _app.AppService.environment.backendUrl);
      if (!userChallenge) throw new Error('challenge type not taken into account');
      return userChallenge;
    } catch (err) {
      console.error('[UserService][joinChallenge]', err);
      throw err;
    }
  }
  static async getUserChallenge(geogroupId, challengeId) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/geogroups/${geogroupId}/challenges/${challengeId}`);
      const userChallenge = parseUserChallenge(data, _app.AppService.environment.backendUrl);
      if (!userChallenge) throw new Error('challenge type not taken into account');
      return userChallenge;
    } catch (err) {
      console.error('[UserService][getUserChallenge]', err);
      throw new Error('not found');
    }
  }
  static async getUserChallenges(_ref11) {
    let {
      groupIds,
      challengeIds,
      page,
      pageSize,
      query,
      eventId
    } = _ref11;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const queryParams = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }, {
        key: 'target_type[]',
        value: 'TRAVELED_DISTANCE'
      }, {
        key: 'target_type[]',
        value: 'CYCLING_DAYS'
      }, {
        key: 'target_type[]',
        value: 'TRACES_COUNT'
      }];
      if (groupIds) queryParams.push({
        key: 'group_id',
        value: groupIds.join(',')
      });
      if (challengeIds) queryParams.push({
        key: 'challenge_id',
        value: challengeIds.join(',')
      });
      if (eventId) queryParams.push({
        key: 'event_id',
        value: eventId
      });
      const {
        results
      } = await _http.HttpService.get('v5', `/users/${this.currentUser.id}/group_challenges_progresses`, queryParams);
      return results.reduce((res, data) => {
        const userChallenge = parseUserChallengeV5(data, _app.AppService.environment.backendUrl);
        if (userChallenge) res.push(userChallenge);
        return res;
      }, []);
    } catch (err) {
      console.error('[UserService][getUserChallenges]', err);
      throw err;
    }
  }
  static async leaveChallenge(geogroupId, challengeId) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v2', `/users/${this.currentUser.id}/geogroups/${geogroupId}/challenges/${challengeId}`);
    } catch (err) {
      console.error('[UserService][removeRide]', err);
    }
  }
  static async getRides() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/rides`);
      const rides = data.reduce((res, rideData) => {
        const ride = parseUserRide(rideData);
        if (ride) res.push(ride);
        return res;
      }, []);
      return rides;
    } catch (err) {
      console.error('[UserService][getRides]', err);
      return [];
    }
  }
  static async addRide(id) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v2', `/users/${this.currentUser.id}/rides`, [], [], JSON.stringify({
        ride_id: id
      }));
      return parseUserRide(data);
    } catch (err) {
      console.error('[UserService][addRide]', err);
      return null;
    }
  }
  static async removeRide(id) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v2', `/users/${this.currentUser.id}/rides/${id}`);
    } catch (err) {
      console.error('[UserService][removeRide]', err);
    }
  }
  static async updateUserOptions(userId, props) {
    try {
      const data = await _http.HttpService.put('v1', `/users/${userId}/options`, [], [], JSON.stringify(props));
      const options = parseUserOptions(data);
      return options;
    } catch (err) {
      console.error('[UserService][updateUserOptions]', err);
      throw err;
    }
  }
  static async updateUserParameters(_ref12) {
    let {
      language,
      ..._props
    } = _ref12;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const props = _props;
      if (language) {
        props.language = languageCodes[language];
      }
      const data = await _http.HttpService.put('v1', `/users/${this.currentUser.id}/parameters`, [], [], JSON.stringify(props));
      const parameters = parseUserParameters(data);
      return parameters;
    } catch (err) {
      console.error('[UserService][updateUserParameters]', err);
      throw err;
    }
  }
  static async getReferenceTrips(_ref13) {
    let {
      page,
      pageSize,
      orderBy,
      order,
      query
    } = _ref13;
    try {
      const params = [];
      if (!this.currentUser) throw new Error('user not connected');
      if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v3', `/users/${this.currentUser.id}/reference_trips`, [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }, ...params]);
      return {
        count,
        next,
        referenceTrips: results.map(parseUserReferenceTrip)
      };
    } catch (err) {
      console.error('[ReferenceTripService][getReferenceTrips]', err);
      throw err;
    }
  }
  static async getReferenceTripOccurrences(_ref14) {
    let {
      page,
      pageSize,
      orderBy,
      order,
      period,
      dateStart,
      dateEnd,
      query
    } = _ref14;
    try {
      const params = [];
      if (!this.currentUser) throw new Error('user not connected');
      if (period === 'custom' && (dateStart === undefined || dateEnd === undefined)) throw new Error('dateStart & dateEnd are required in custom mode');
      if (period !== 'custom' && (dateStart !== undefined || dateEnd !== undefined)) throw new Error('dateStart & dateEnd are incompatible with this period mode');
      if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (period) {
        params.push({
          key: 'period',
          value: period
        });
      }
      if (dateStart) {
        params.push({
          key: 'date_start',
          value: dateStart.format('DD-MM-YYYY')
        });
      }
      if (dateEnd) {
        params.push({
          key: 'date_end',
          value: dateEnd.format('DD-MM-YYYY')
        });
      }
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v4', `/users/${this.currentUser.id}/user_reference_trip_occurrences`, [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }, ...params]);
      return {
        count,
        next,
        referenceTripOccurrences: results.map(occurrence => parseUserReferenceTripOccurrence(occurrence))
      };
    } catch (err) {
      console.error('[ReferenceTripOccurrenceService][getReferenceTripOccurrences]', err);
      throw err;
    }
  }
  static async addReferenceTripOccurrence(referenceTrip, _ref15) {
    let {
      date,
      direction
    } = _ref15;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v3', `/users/${this.currentUser.id}/reference_trips/${referenceTrip.id}/occurrences`, [], [], JSON.stringify({
        date,
        comment: '',
        direction: toBackendTripDirection(direction)
      }));
      return parseUserReferenceTripOccurrence(data);
    } catch (err) {
      console.error('[ReferenceTripOccurrenceService][addReferenceTripOccurrence]', err);
      return null;
    }
  }
  static async updateUserReferenceTripOccurrence(_ref16, _ref17) {
    let {
      id,
      userReferenceTripId
    } = _ref16;
    let {
      date,
      comment,
      direction,
      enabled
    } = _ref17;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const params = {
        date,
        direction: toBackendTripDirection(direction),
        enabled
      };
      if (comment !== undefined) params.comment = comment;
      const data = await _http.HttpService.put('v3', `/users/${this.currentUser.id}/reference_trips/${userReferenceTripId}/occurrences/${id}`, [], [], JSON.stringify(params));
      return parseUserReferenceTripOccurrence(data);
    } catch (err) {
      console.error('[ReferenceTripOccurrenceService][updateReferenceTripOccurrence]', err);
      throw err;
    }
  }
  static async removeReferenceTripOccurrence(_ref18) {
    let {
      userReferenceTripId,
      id
    } = _ref18;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v3', `/users/${this.currentUser.id}/reference_trips/${userReferenceTripId}/occurrences/${id}`);
      return true;
    } catch (err) {
      console.error('[ReferenceTripOccurrenceService][removeReferenceTripOccurrence]', err);
      throw err;
    }
  }
  static async getTraceProblems(traceId) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/traces/${traceId}/user_trace_problems`);
      return data.map(parseTraceProblem);
    } catch (err) {
      console.error('[UserService][getTraceProblem]', err);
      return [];
    }
  }

  // TODO: remove when multimodal will be finished
  static async getTracesV5(_ref19) {
    let {
      user,
      period,
      ordering,
      page,
      rowsPerPage,
      condensed,
      query,
      retrieveProblems
    } = _ref19;
    try {
      if (!user && !this.currentUser) throw new Error('user not connected');
      const params = [];
      if (period) {
        params.push({
          key: 'period',
          value: 'custom'
        }, {
          key: 'date_start',
          value: period.from.format('DD-MM-yyyy')
        }, {
          key: 'date_end',
          value: period.to.format('DD-MM-yyyy')
        });
      }
      if (ordering) params.push({
        key: 'ordering',
        value: ordering
      });
      if (page) params.push({
        key: 'page',
        value: page
      });
      if (rowsPerPage) params.push({
        key: 'page_size',
        value: rowsPerPage
      });
      if (condensed) params.push({
        key: 'condensed',
        value: condensed
      });
      if (query) params.push({
        key: 'query',
        value: query
      });
      const {
        count,
        results
      } = await _http.HttpService.get('v5', `/users/${user?.id || this.currentUser?.id}/traces`, params);
      const problems = await Promise.all(results.map(_ref20 => {
        let {
          id,
          has_problem
        } = _ref20;
        return retrieveProblems && has_problem ? UserService.getTraceProblems(id) : [];
      }));
      return {
        count,
        results: results.map((trace, index) => parseTraceV5(trace, problems[index]))
      };
    } catch (err) {
      console.error('[UserService][getTracesV5]', err);
      throw err;
    }
  }
  static async getTraces(_ref21) {
    let {
      user,
      period,
      ordering,
      page,
      rowsPerPage,
      condensed,
      query,
      retrieveProblems
    } = _ref21;
    try {
      if (!user && !this.currentUser) throw new Error('user not connected');
      const params = [];
      if (period) {
        params.push({
          key: 'period',
          value: 'custom'
        }, {
          key: 'date_start',
          value: period.from.format('DD-MM-yyyy')
        }, {
          key: 'date_end',
          value: period.to.format('DD-MM-yyyy')
        });
      }
      if (ordering) params.push({
        key: 'ordering',
        value: ordering
      });
      if (page) params.push({
        key: 'page',
        value: page
      });
      if (rowsPerPage) params.push({
        key: 'page_size',
        value: rowsPerPage
      });
      if (condensed) params.push({
        key: 'condensed',
        value: condensed
      });
      if (query) params.push({
        key: 'query',
        value: query
      });
      const {
        count,
        results
      } = await _http.HttpService.get('v6', `/users/${user?.id || this.currentUser?.id}/traces`, params);
      const problems = await Promise.all(results.map(_ref22 => {
        let {
          id,
          has_problem
        } = _ref22;
        return retrieveProblems && has_problem ? UserService.getTraceProblems(id) : [];
      }));
      return {
        count,
        results: results.map((trace, index) => parseTrace(trace, problems[index]))
      };
    } catch (err) {
      console.error('[UserService][getTraces]', err);
      throw err;
    }
  }
  static async getSimplifiedTraces(_ref23) {
    let {
      period
    } = _ref23;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const params = [{
        key: 'date_start',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: period.to.format('YYYY-MM-DD')
      }];
      const res = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/simplified_traces`, params);
      return res;
    } catch (err) {
      console.error('[UserService][getSimplifiedTraces]', err);
      throw err;
    }
  }

  // TODO: remove when multimodal will be finished
  static async getTraceV4(userId, id) {
    try {
      const result = await _http.HttpService.get('v4', `/users/${userId}/traces/${id}`);
      const problems = result.has_problem ? await UserService.getTraceProblems(id) : [];
      return parseTrace(result, problems);
    } catch (err) {
      console.error('[UserService][getTraceV4]', err);
      throw err;
    }
  }
  static async getTrace(userId, id) {
    try {
      const result = await _http.HttpService.get('v6', `/users/${userId}/traces/${id}`);
      const problems = result.has_problem ? await UserService.getTraceProblems(id) : [];
      return parseTrace(result, problems);
    } catch (err) {
      console.error('[UserService][getTrace]', err);
      throw err;
    }
  }

  // TODO: remove when multimodal will be finished
  static async tagTraceAsHomeWorkV3(_ref24) {
    let {
      userTrace,
      direction
    } = _ref24;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v3', `/users/${this.currentUser.id}/user_trace/${userTrace.id}/commuting`, [], [], JSON.stringify({
        direction: toBackendTripDirection(direction)
      }));
      return parseUserReferenceTripOccurrence(data);
    } catch (err) {
      console.error('[UserService][tagTraceAsHomeWorkV3]', err);
      throw err;
    }
  }
  static async tagTraceAsHomeWork(_ref25) {
    let {
      userTraceId,
      referenceTripOccurrenceId: occurrence_id
    } = _ref25;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const {
        commuting_trip_occurrence: data
      } = await _http.HttpService.post('v6', `/users/${this.currentUser.id}/user_trace/${userTraceId}/commuting`, [], [], JSON.stringify({
        occurrence_id
      }));
      return (0, _commutingTrip.parseCommutingTripOccurrence)(data);
    } catch (err) {
      console.error('[UserService][tagTraceAsHomeWork]', err);
      throw err;
    }
  }

  // TODO: remove when multimodal will be finished
  static async untagHomeWorkTraceV3(_ref26) {
    let {
      userTrace
    } = _ref26;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v3', `/users/${this.currentUser.id}/user_trace/${userTrace.id}/commuting`, [], [], null, {
        expectedStatus: 204
      });
      return true;
    } catch (err) {
      console.error('[UserService][untagHomeWorkTraceV3]', err);
      throw err;
    }
  }
  static async untagHomeWorkTrace(_ref27) {
    let {
      userTraceId
    } = _ref27;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v6', `/users/${this.currentUser.id}/user_trace/${userTraceId}/commuting`, [], [], null, {
        expectedStatus: 204
      });
      return true;
    } catch (err) {
      console.error('[UserService][untagHomeWorkTrace]', err);
      throw err;
    }
  }
  static async tagTraceAsBike(_ref28) {
    let {
      userTrace
    } = _ref28;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      await _http.HttpService.patch('v2', `/traces/${userTrace.id}/transport_mode`, [], [], JSON.stringify({
        is_bike_manually_classified: true
      }));
      return true;
    } catch (err) {
      console.error('[UserService][tagTraceAsBike]', err);
      throw err;
    }
  }
  static async getRawTrace(userId, traceId) {
    try {
      const {
        id
      } = await _http.HttpService.get('v2', `/users/${userId}/traces/${traceId}/raw_user_trace`);
      return id;
    } catch (err) {
      console.error('[UserService][getRawTrace]', err);
      throw err;
    }
  }
  static async getTracePreviewUrl(_ref29) {
    let {
      traceId
    } = _ref29;
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const {
        url
      } = await _http.HttpService.post('v2', `/users/${this.currentUser.id}/traces/${traceId}/preview`);
      return url;
    } catch (err) {
      console.error('[UserService][getTracePreviewUrl]', err);
      throw err;
    }
  }
  static async getLogs(userId) {
    try {
      const results = await _http.HttpService.get('v1', `/users/${userId}/logs`);
      return results[0].logs || null;
    } catch (err) {
      console.error('[UserService][getLogs]', err);
      throw err;
    }
  }
  static async updateUserTraceGroundTruth(_ref30) {
    let {
      traceId,
      groundTruth
    } = _ref30;
    try {
      await _http.HttpService.put('v2', `/traces/${traceId}/groundtruth`, [], [], JSON.stringify({
        ground_truth: groundTruth
      }));
      return true;
    } catch (err) {
      console.error('[UserService][updateUserTraceGroundTruth]', err);
      throw err;
    }
  }
  static async importGPXTrace(gpx, title) {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const formData = new FormData();
      formData.append('gpx', gpx);
      formData.append('title', title);
      const {
        id
      } = await _http.HttpService.post('v2', `/user_trace_from_gpx`, [], [], formData, {
        expectedStatus: 201
      });
      return UserService.getTrace(this.currentUser.id, id);
    } catch (err) {
      console.error('[UserService][importGPXTrace]', err);
      if (err instanceof Object && 'id' in err) throw 'AlreadyExists';
      if (err === 'Missing at least time information of one point in this GPX' || err === 'Too few points contained in this GPX file') throw 'MissingData';
      throw new Error(err.error);
    }
  }
  static async generateUsername() {
    try {
      const {
        username
      } = await _http.HttpService.get('v1', `/username_generator`);
      return username;
    } catch (err) {
      console.error('[UserService][generateUsername]', err);
      throw err;
    }
  }
  static async getUserGameLevel() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const {
        main_level_points,
        level,
        level_thresholds,
        badges_contribution
      } = await _http.HttpService.get('v2', `/users/${this.currentUser.id}/game_main_level`);
      return {
        mainLevelPoints: main_level_points,
        level,
        levelThresholds: level_thresholds || [],
        pointsDetails: badges_contribution?.map(_ref31 => {
          let {
            code: badgeKey,
            main_level_points: points
          } = _ref31;
          return {
            badgeKey,
            points
          };
        }) || undefined
      };
    } catch (err) {
      console.error('[UserService][getUserGameLevel]', err);
      throw err;
    }
  }
  static async getPublicProfile(_ref32) {
    let {
      userId
    } = _ref32;
    try {
      const res = await _http.HttpService.get('v2', `/users/${userId}/public_profile`);
      return parsePublicProfile(res);
    } catch (err) {
      console.error('[UserService][getPublicProfile]', err);
      throw err;
    }
  }
  static async impersonate(_ref33) {
    let {
      userId,
      totpToken
    } = _ref33;
    try {
      const {
        key: token
      } = await _http.HttpService.get('v1', `/user_impersonate`, [{
        key: 'user_id',
        value: userId
      }, {
        key: 'totp_token',
        value: totpToken
      }]);
      return {
        token
      };
    } catch (err) {
      console.error('[UserService][impersonate]', err);
      throw err;
    }
  }
  static async getBestEfforts() {
    try {
      if (!this.currentUser) throw new Error('user not connected');
      const {
        max_distance_trace: maxDistanceTrace,
        max_duration_trace: maxDurationTrace,
        max_altitude_gain_trace
      } = await _http.HttpService.get('v1', `/users/${this.currentUser.id}/personal_best`);
      return {
        maxDistanceTrace,
        maxDurationTrace,
        maxVerticalGainTrace: max_altitude_gain_trace ? {
          id: max_altitude_gain_trace.id,
          verticalGain: max_altitude_gain_trace.vertical_gain
        } : null
      };
    } catch (err) {
      console.error('[UserService][getBestEfforts]', err);
      throw err;
    }
  }
}
exports.UserService = UserService;
function parseUserOptions(_ref34) {
  let {
    dashboard_newsletter: dashboardNewsletter,
    receive_email: receiveEmails,
    receive_newsletter: receiveNewsletter
  } = _ref34;
  return {
    dashboardNewsletter,
    receiveEmails,
    receiveNewsletter
  };
}
const languageCodes = exports.languageCodes = {
  en: 'en-GB',
  fr: 'fr-FR',
  es: 'es-ES',
  de: 'de-DE'
};
const languagesByCountry = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  de: 'de'
};
function parseUserParameters(_ref35) {
  let {
    averageSpeed,
    bikeType,
    e_bike: eBike,
    profile,
    language: _language
  } = _ref35;
  const specificLanguagesMap = {
    // 'fr-CH': 'fr-CH'
  };
  const country = _language && _language.length >= 2 && _language.substr(0, 2).toLowerCase() || null;
  const language = specificLanguagesMap[_language] || country && languagesByCountry[country] || 'fr';
  return {
    averageSpeed,
    bikeType: _route.backendBikeTypes[bikeType],
    eBike,
    profile: _cyclingProfile.backendCyclingProfiles[profile],
    language
  };
}
function parseUser(_ref36) {
  let {
    id,
    email,
    username,
    date_of_birth,
    first_name,
    last_name,
    is_admin,
    is_support,
    is_staff,
    options,
    parameters,
    created,
    phone_number,
    profile_picture
  } = _ref36;
  return new _models.User(id, email, username || null, date_of_birth ? new Date(date_of_birth) : null, first_name || null, last_name || null, is_admin || false, is_support || false, is_staff || false, parseUserOptions(options), parseUserParameters(parameters), (0, _moment.default)(created), phone_number || null, profile_picture ? `${_environment.defaultEnvironment.backendUrl}${profile_picture}` : undefined);
}
function parseMinimalUser(_ref37) {
  let {
    id,
    email,
    username,
    created
  } = _ref37;
  return new _models.User(id, email || '', username || null, null, null, null, false, false, false, {
    dashboardNewsletter: false,
    receiveEmails: false,
    receiveNewsletter: false
  }, {
    averageSpeed: 15,
    bikeType: 'own',
    eBike: false,
    language: 'fr',
    profile: 'daily'
  }, (0, _moment.default)(created), null);
}
function parseUserTracesStats(_ref38) {
  let {
    count,
    home_work_reference_trips_count,
    distance,
    home_work_reference_trips_distance,
    duration,
    home_work_reference_trips_duration,
    avg_speed,
    vertical_gain,
    data
  } = _ref38;
  return new _models.UserTracesStats(count || 0, home_work_reference_trips_count || 0, distance || 0, home_work_reference_trips_distance || 0, duration || 0, home_work_reference_trips_duration || 0, avg_speed || 0, vertical_gain || 0, data?.map(_ref39 => {
    let {
      count,
      home_work_reference_trips_count,
      distance,
      home_work_reference_trips_distance,
      duration,
      home_work_reference_trips_duration,
      avg_speed,
      vertical_gain,
      unit
    } = _ref39;
    return {
      count,
      homeWorkCount: home_work_reference_trips_count || home_work_reference_trips_count === 0 ? home_work_reference_trips_count : undefined,
      distance,
      homeWorkDistance: home_work_reference_trips_distance || home_work_reference_trips_distance === 0 ? home_work_reference_trips_distance : undefined,
      duration,
      homeWorkDuration: home_work_reference_trips_duration || home_work_reference_trips_duration === 0 ? home_work_reference_trips_duration : undefined,
      averageSpeed: avg_speed,
      verticalGain: vertical_gain,
      unit: (0, _moment.default)(unit)
    };
  }) || []);
}
function parseUserPlace(_ref40) {
  let {
    id,
    type,
    title,
    geo_point,
    address,
    created
  } = _ref40;
  const types = {
    HOME: 'home',
    OTHER: 'other',
    WORK: 'work',
    TRANSPORT_MODE_CHANGE: 'transportModeChange'
  };
  return new _models.UserPlace(id, types[type], title, geo_point, address, (0, _moment.default)(created));
}
function parseUserChallenge(_ref41, backendUrl) {
  let {
    challenge: _challenge,
    progress_value,
    rank,
    leaderboard_index
  } = _ref41;
  const challenge = (0, _geogroup.parseChallenge)(_challenge, backendUrl);
  if (!challenge) return null;
  return new _models.UserChallenge(challenge, progress_value, rank, leaderboard_index ? leaderboard_index - 1 : undefined);
}
function parseUserChallengeV5(_ref42, backendUrl) {
  let {
    challenge: _challenge,
    progress_value,
    leaderboard
  } = _ref42;
  const challenge = (0, _geogroup.parseChallenge)(_challenge, backendUrl);
  if (!challenge) return null;
  return new _models.UserChallenge(challenge, progress_value, leaderboard?.rank, leaderboard?.index ? leaderboard.index - 1 : undefined, leaderboard?.subgroups?.filter(_ref43 => {
    let {
      is_member
    } = _ref43;
    return is_member;
  }).map(_ref44 => {
    let {
      id: subGroupId,
      count_subgroup_of_same_type: countSubgroupOfSameType,
      progress_value: progressValue,
      rank,
      sub_group_type,
      title
    } = _ref44;
    return {
      subGroupId,
      countSubgroupOfSameType,
      progressValue,
      rank,
      subGroupType: sub_group_type === 'COMPANY_SITE' ? 'sites' : 'teams',
      title
    };
  }));
}
function parseUserRide(_ref45) {
  let {
    created,
    ride: rideId
  } = _ref45;
  return new _models.UserRide(rideId, (0, _moment.default)(created));
}
function parseUserReferenceTrip(_ref46) {
  let {
    id,
    created,
    title,
    geo_start,
    geo_start_title,
    geo_end,
    geo_end_title,
    distance_in_meters_start_end,
    distance_in_meters_end_start,
    enabled
  } = _ref46;
  function toPlace(geoPoint, address) {
    return new _models.Place(undefined, geoPoint, {
      primaryText: address
    });
  }
  return new _models.UserReferenceTrip(id, (0, _moment.default)(created), title, toPlace(geo_start, geo_start_title), toPlace(geo_end, geo_end_title), distance_in_meters_start_end, distance_in_meters_end_start, enabled);
}
function parseUserReferenceTripOccurrence(_ref47) {
  let {
    id,
    created,
    date,
    certified_by_geovelo,
    manually_created,
    comment,
    direction,
    user_trace,
    enabled,
    user_reference_trip
  } = _ref47;
  return new _models.UserReferenceTripOccurrence(id, user_reference_trip, (0, _moment.default)(created), (0, _moment.default)(date), date, Boolean(certified_by_geovelo), manually_created && !user_trace, comment, toTripDirection(direction), Boolean(user_trace), enabled);
}
function toBackendTripDirection(direction) {
  if (direction === 'outward') return 'OUTWARD';
  return 'RETURN';
}
function toTripDirection(direction) {
  if (direction === 'OUTWARD') return 'outward';
  return 'return';
}
const traceRecordSources = {
  ACTIVITY_DETECTION: 'activityDetection',
  IMPORT_GPX: 'importedGPX',
  IMPORT_GARMIN: 'importedGarmin',
  MANUAL: 'manual',
  NAVIGATION: 'navigation',
  NAVIGATION_RIDE: 'navigationRide',
  UNKNOWN: 'unknown'
};
const userTraceDeletionReasons = exports.userTraceDeletionReasons = ['NOT_BIKE', 'GEOMETRY_NOT_EXACT', 'DUPLICATE', 'OTHER'];
const consideredProblems = ['highSpeed', 'notBicycle', 'tooLong'];
function parseTraceV5(_ref48, problems) {
  let {
    id,
    title,
    start_datetime,
    end_datetime,
    geometry,
    distance,
    cleaned_distance,
    duration,
    cleaned_duration,
    vertical_gain,
    cleaned_vertical_gain,
    average_speed,
    cleaned_avg_speed,
    speeds,
    elevations,
    computed_route_id,
    preview,
    record_source,
    has_problem,
    challenges,
    clean_status,
    is_home_work,
    is_forced_not_home_work,
    first_point_near_work,
    last_point_near_work,
    user_reference_trip_occurrence,
    usertracegameprogress,
    is_bike_automatically_classified,
    is_bike_manually_classified
  } = _ref48;
  const isPotentiallyBike = clean_status === 'CLEAN' && is_bike_automatically_classified === false && is_bike_manually_classified === null;
  return new _trace.Trace(id, title || (0, _moment.default)(start_datetime).format('DD_MMM_hh[h]mm') + '_-_' + (0, _moment.default)(end_datetime).format('hh[h]mm'), (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), geometry, distance || distance === 0 ? distance : cleaned_distance, duration || duration === 0 ? duration : cleaned_duration, vertical_gain || vertical_gain === 0 ? vertical_gain : cleaned_vertical_gain, average_speed || average_speed === 0 ? average_speed : cleaned_avg_speed, speeds, elevations, !!computed_route_id, preview || null, has_problem, problems?.filter(_ref49 => {
    let {
      problemType,
      fixDatetime
    } = _ref49;
    return consideredProblems.includes(problemType) && !fixDatetime;
  }) || [], traceRecordSources[record_source] || 'unknown', is_home_work ? 'commuting' : is_forced_not_home_work ? 'forcedNotCommuting' : first_point_near_work || last_point_near_work ? 'candidate' : 'notCommuting', challenges, clean_status === 'CLEAN' ? 'cleaned' : clean_status === 'NO_CLEAN' ? 'not_cleaned' : undefined, user_reference_trip_occurrence?.[0] && (first_point_near_work || last_point_near_work) ? [(0, _commutingTrip.parseCommutingTripPotentialOccurrence)({
    id: user_reference_trip_occurrence[0],
    date: start_datetime,
    direction: first_point_near_work ? 'RETURN' : 'OUTWARD',
    candidate: !is_home_work && !is_forced_not_home_work,
    certified_by_geovelo: is_bike_automatically_classified || false,
    manually_created: is_bike_manually_classified || false,
    enabled: true,
    user_trace: id
  })] : [], {
    longDistance: usertracegameprogress?.distance_over_50km || false,
    verticalGain: usertracegameprogress?.altitude_gain_over_100 || false,
    nightBird: usertracegameprogress?.during_night || false
  }, isPotentiallyBike);
}
function parseTrace(_ref50, problems) {
  let {
    id,
    title,
    start_datetime,
    end_datetime,
    geometry,
    distance,
    cleaned_distance,
    duration,
    cleaned_duration,
    vertical_gain,
    cleaned_vertical_gain,
    average_speed,
    cleaned_avg_speed,
    speeds,
    elevations,
    computed_route_id,
    preview,
    record_source,
    has_problem,
    challenges,
    clean_status,
    commuting_status,
    user_reference_trip_occurrence,
    usertracegameprogress,
    is_bike_automatically_classified,
    is_bike_manually_classified
  } = _ref50;
  const isPotentiallyBike = clean_status === 'CLEAN' && is_bike_automatically_classified === false && is_bike_manually_classified === null;
  return new _trace.Trace(id, title || (0, _moment.default)(start_datetime).format('DD_MMM_hh[h]mm') + '_-_' + (0, _moment.default)(end_datetime).format('hh[h]mm'), (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), geometry, distance || distance === 0 ? distance : cleaned_distance, duration || duration === 0 ? duration : cleaned_duration, vertical_gain || vertical_gain === 0 ? vertical_gain : cleaned_vertical_gain, average_speed || average_speed === 0 ? average_speed : cleaned_avg_speed, speeds, elevations, !!computed_route_id, preview || null, has_problem, problems?.filter(_ref51 => {
    let {
      problemType,
      fixDatetime
    } = _ref51;
    return consideredProblems.includes(problemType) && !fixDatetime;
  }) || [], traceRecordSources[record_source] || 'unknown', commuting_status === 'COMMUTING' ? 'commuting' : commuting_status === 'COMMUTING_CANDIDATE' ? 'candidate' : commuting_status === 'FORCE_NOT_COMMUTING' ? 'forcedNotCommuting' : 'notCommuting', challenges, clean_status === 'CLEAN' ? 'cleaned' : clean_status === 'NO_CLEAN' ? 'not_cleaned' : undefined, (user_reference_trip_occurrence || []).map(_commutingTrip.parseCommutingTripPotentialOccurrence), {
    longDistance: usertracegameprogress?.distance_over_50km || false,
    verticalGain: usertracegameprogress?.altitude_gain_over_100 || false,
    nightBird: usertracegameprogress?.during_night || false
  }, isPotentiallyBike);
}
const problemTypes = {
  CLEAN_FAILURE: 'cleanFailure',
  GEOMETRY_JUMP: 'geometryJump',
  HIGH_SPEED: 'highSpeed',
  LONG_DURATION: 'tooLong',
  MERGING: 'merging',
  NOT_BICYCLE: 'notBicycle',
  SHORT_DISTANCE: 'shortDistance'
};
function parseTraceProblem(_ref52) {
  let {
    problem_type,
    fix_datetime
  } = _ref52;
  return new _trace.TraceProblem(problemTypes[problem_type], fix_datetime ? new Date(fix_datetime) : null);
}
function parsePublicProfile(_ref53) {
  let {
    username,
    profile_picture,
    count_traces,
    total_distance_in_meters,
    total_duration_in_seconds,
    average_speed_kmph,
    usergameprogress: {
      main_level,
      level_badges,
      conditional_badges,
      achievements
    }
  } = _ref53;
  return {
    user: {
      profilePicture: profile_picture ? `${_environment.defaultEnvironment.backendUrl}${profile_picture}` : profile_picture,
      username
    },
    gameLevel: main_level ? {
      mainLevelPoints: main_level.main_level_points,
      level: main_level.level,
      levelThresholds: main_level.level_thresholds || []
    } : undefined,
    stats: {
      [_models.StatsTypes.Count]: count_traces,
      [_models.StatsTypes.Distance]: total_distance_in_meters,
      [_models.StatsTypes.Duration]: total_duration_in_seconds,
      [_models.StatsTypes.VerticalGain]: 0,
      [_models.StatsTypes.AverageSpeed]: average_speed_kmph
    },
    badgeLevels: (0, _badge.parseBadgeLevels)({
      level_badges: level_badges || []
    }),
    conditionalBadges: (0, _badge.parseConditionalBadges)({
      conditional_badges: conditional_badges || []
    }),
    daysBadges: (achievements || []).map(_ref54 => {
      let {
        title: key,
        date
      } = _ref54;
      return {
        key,
        date: (0, _moment.default)(date)
      };
    })
  };
}