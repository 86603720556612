import { TPartnerContract } from '@geovelo-frontends/commons';
import { Box, Tooltip, Typography } from '@mui/material';

import { PremiumIcon } from './icons';

export function PremiumBadge({
  contract,
  targetOffer: offer,
  limited,
}: {
  contract: TPartnerContract;
  limited?: boolean;
  targetOffer?: 'premium';
}): JSX.Element {
  return (
    <Tooltip
      placement="bottom"
      title={
        limited
          ? 'Cette fonctionnalité est limitée en offre Gratuite'
          : contract.contractTemplate.code === 'geovelo-entreprise-free'
            ? offer === 'premium'
              ? "Cette fonctionnalité n'est disponible que dans l'offre Premium"
              : "Cette fonctionnalité n'est disponible que dans les offres payantes"
            : ''
      }
    >
      <Box alignItems="center" display="flex" gap={1}>
        <Box
          alignItems="center"
          bgcolor="#D7C7F6"
          borderRadius={2}
          display="flex"
          gap={0.5}
          paddingX={1}
          paddingY={0.5}
        >
          <PremiumIcon sx={{ color: '#6236BD', height: 14, width: 14 }} />
          <Typography color="#491EA1" lineHeight="14px" variant="caption">
            {limited ? 'Fonctionnalité limitée' : 'Fonctionnalité premium'}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}
