"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backendRolesMap = exports.EmployeeService = void 0;
exports.parseCertificate = parseCertificate;
exports.parseEmployee = parseEmployee;
var _moment = _interopRequireDefault(require("moment"));
var _environment = require("../environment");
var _models = require("../models");
var _app = require("./app");
var _commutingTrip = require("./commuting-trip");
var _http = require("./http");
var _partner = require("./partner");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class EmployeeService {
  static async createEmployee(partner, _ref) {
    let {
      userId,
      firstName,
      lastName,
      professionalEmail,
      eulaAccepted,
      privacyPolicyAccepted,
      receiveNewsletter,
      surveyAnswers,
      invitationCode,
      role
    } = _ref;
    try {
      const params = userId ? {
        user: {
          id: userId
        }
      } : {};
      if (firstName) params.first_name = firstName;
      if (lastName) params.last_name = lastName;
      if (professionalEmail) params.professional_email = professionalEmail;
      if (eulaAccepted !== undefined) params.cgu_accepted = eulaAccepted;
      if (privacyPolicyAccepted !== undefined) params.privacy_policy_accepted = privacyPolicyAccepted;
      if (receiveNewsletter) params.receive_newsletter = receiveNewsletter;
      if (surveyAnswers) params.survey = surveyAnswers;
      if (invitationCode) params.invitation_code = invitationCode;
      if (role) params.role = backendRolesMap[role];
      const data = await _http.HttpService.post('v1', `/partners/${partner.id}/employees`, [], [], JSON.stringify(params), {
        expectedStatus: 201,
        partner: partner.code || ''
      });
      return parseEmployee(data);
    } catch (err) {
      console.error('[EmployeeService][createEmployee]', err);
      throw err;
    }
  }
  static async getEmployees() {
    let {
      query
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const queryParams = [{
        key: 'join_statuses',
        value: 'EMPLOYEE_JOIN,EMPLOYEE_WAITING_FOR_APPROVAL'
      }];
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      const data = (await _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/employees`, queryParams)).sort((a, b) => {
        if (a.join_status === 'EMPLOYEE_JOIN') return b.join_status === 'EMPLOYEE_JOIN' ? 0 : -1;
        if (b.join_status === 'EMPLOYEE_JOIN') return 1;
        return 0;
      });
      return data.map(parseEmployeeAndPartner).reduce((res, _ref2) => {
        let {
          employee,
          partner
        } = _ref2;
        if (partner) res.push({
          employee,
          partner
        });
        return res;
      }, []);
    } catch (err) {
      console.error('[EmployeeService][getEmployees]', err);
      throw err;
    }
  }
  static async getPartnerEmployee(_ref3) {
    let {
      partner,
      id
    } = _ref3;
    try {
      const data = await _http.HttpService.get('v1', `/partners/${partner.id}/employees/${id}`, [], [], null, {
        partner: partner.code
      });
      if (!data) throw new Error('employee not found');
      return parseEmployee(data);
    } catch (err) {
      console.error('[EmployeeService][getPartnerEmployee]', err);
      throw err;
    }
  }
  static async linkToUser(partnerCode, token) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('v1', `/employees/${token}/link_user`, [], [], null, {
        partner: partnerCode
      });
      if (!data) throw new Error('employee not found');
      return parseEmployeeAndPartner(data);
    } catch (err) {
      console.error('[EmployeeService][linkToUser]', err);
      throw err;
    }
  }
  static async updateEmployee(_ref4, employeeId, _ref5) {
    let {
      id: partnerId,
      code: partner
    } = _ref4;
    let {
      role,
      isFMDBeneficiary,
      firstName,
      lastName,
      professionalEmail,
      companyEmployeeId,
      eulaAccepted,
      privacyPolicyAccepted,
      receiveNewsletter,
      surveyAnswers
    } = _ref5;
    try {
      const params = {};
      if (role !== undefined) params.role = backendRolesMap[role];
      if (isFMDBeneficiary !== undefined) params.can_declare_fmd = isFMDBeneficiary;
      if (firstName !== undefined) params.first_name = firstName;
      if (lastName !== undefined) params.last_name = lastName;
      if (professionalEmail !== undefined) params.professional_email = professionalEmail;
      if (companyEmployeeId !== undefined) params.company_employee_id = companyEmployeeId;
      if (eulaAccepted !== undefined) params.cgu_accepted = eulaAccepted;
      if (privacyPolicyAccepted !== undefined) params.privacy_policy_accepted = privacyPolicyAccepted;
      if (receiveNewsletter !== undefined) params.receive_newsletter = receiveNewsletter;
      if (surveyAnswers) params.survey = surveyAnswers;
      const data = await _http.HttpService.patch('v1', `/partners/${partnerId}/employees/${employeeId}`, [], [], JSON.stringify(params), {
        partner
      });
      return parseEmployee(data);
    } catch (err) {
      console.error('[EmployeeService][updateEmployee]', err);
      throw err;
    }
  }
  static async deleteEmployee(_ref6, employeeId) {
    let {
      id: partnerId,
      code: partner
    } = _ref6;
    try {
      await _http.HttpService.delete('v1', `/partners/${partnerId}/employees/${employeeId}`, [], [], null, partner ? {
        partner
      } : undefined);
      return true;
    } catch (err) {
      console.error('[EmployeeService][deleteEmployee]', err);
      throw err;
    }
  }
  static async getFMDStats(_ref7) {
    let {
      partner,
      employeeId,
      startDate,
      endDate
    } = _ref7;
    try {
      const {
        amount_to_pay: allowance,
        count_days_total: daysCount,
        sum_distance_total: totalDistance,
        count_home_to_work_journeys: homeToWorkCount,
        count_work_to_home_journeys: workToHomeCount
      } = await _http.HttpService.get('v1', `/employees/${employeeId}/fmd_attestations_stats`, [{
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: startDate.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: endDate.format('YYYY-MM-DD')
      }], [], null, {
        partner: partner.code
      });
      return {
        allowance: allowance || 0,
        daysCount: daysCount || 0,
        totalDistance: totalDistance || 0,
        homeToWorkCount: homeToWorkCount || 0,
        workToHomeCount: workToHomeCount || 0
      };
    } catch (err) {
      console.error('[EmployeeService][getFMDStats]', err);
      throw err;
    }
  }
  static async getFMDEstimation(enableMultimodalJourneys, _ref8) {
    let {
      partner,
      employeeId,
      startDate,
      endDate
    } = _ref8;
    try {
      const {
        amount_to_pay: allowance,
        count_days_total: daysCount,
        sum_distance_manually_declared_journeys,
        sum_distance_user_trace_journeys,
        count_home_to_work_journeys: homeToWorkCount,
        count_work_to_home_journeys: workToHomeCount,
        min_days_count_per_month
      } = await _http.HttpService.get(enableMultimodalJourneys ? 'v2' : 'v1', `/employees/${employeeId}/fmd_attestations_estimate`, [{
        key: 'date_start',
        value: startDate.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: endDate.format('YYYY-MM-DD')
      }], [], null, {
        partner: partner.code
      });
      return {
        allowance: allowance || 0,
        daysCount: daysCount || 0,
        distance: (sum_distance_manually_declared_journeys || 0) + (sum_distance_user_trace_journeys || 0),
        outwardCount: homeToWorkCount || 0,
        returnCount: workToHomeCount || 0,
        dayThresholdNotReached: (daysCount || 0) < (min_days_count_per_month || 0)
      };
    } catch (err) {
      console.error('[EmployeeService][getFMDEstimation]', err);
      throw err;
    }
  }
  static async getCertificates(enableMultimodalJourneys, _ref9) {
    let {
      partner,
      employeeId,
      page,
      pageSize,
      startDate,
      endDate,
      query,
      excludeInvalid
    } = _ref9;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: startDate.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: endDate.format('YYYY-MM-DD')
      }, {
        key: 'exclude_null_amount_to_pay',
        value: false
      }, {
        key: 'exclude_invalid_attestation',
        value: excludeInvalid || false
      }];
      if (query) params.push({
        key: 'query',
        value: query
      });
      const {
        count,
        results
      } = await _http.HttpService.get(enableMultimodalJourneys ? 'v2' : 'v1', `/employees/${employeeId}/fmd_attestations`, params, [], null, {
        partner: partner.code
      });
      return {
        count,
        certificates: results.map(data => parseCertificate(data))
      };
    } catch (err) {
      console.error('[EmployeeService][getCertificates]', err);
      throw err;
    }
  }
  static async createCertificate(enableMultimodalJourneys, _ref10) {
    let {
      partner,
      employeeId,
      startDate,
      endDate
    } = _ref10;
    try {
      const data = await _http.HttpService.post(enableMultimodalJourneys ? 'v2' : 'v1', `/employees/${employeeId}/fmd_attestations`, [], [], JSON.stringify({
        employee: employeeId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD')
      }), {
        partner: partner.code
      });
      return parseCertificate(data);
    } catch (err) {
      console.error('[EmployeeService][generateCertificate]', err);
      throw err;
    }
  }
  static async updateCertificate(enableMultimodalJourneys, _ref11) {
    let {
      partner,
      employeeId,
      certificateId,
      isValid
    } = _ref11;
    try {
      const props = {};
      if (isValid !== undefined) props.is_valid = isValid;
      const data = await _http.HttpService.patch(enableMultimodalJourneys ? 'v2' : 'v1', `/employees/${employeeId}/fmd_attestations/${certificateId}`, [], [], JSON.stringify(props), {
        partner: partner.code
      });
      return parseCertificate(data);
    } catch (err) {
      console.error('[EmployeeService][updateCertificate]', err);
      throw err;
    }
  }
}
exports.EmployeeService = EmployeeService;
const rolesMap = {
  ADMINISTRATOR_GLOBAL: 'administrator',
  ANIMATOR_GLOBAL: 'animator',
  EMPLOYEE: 'employee'
};
const backendRolesMap = exports.backendRolesMap = {
  administrator: 'ADMINISTRATOR_GLOBAL',
  animator: 'ANIMATOR_GLOBAL',
  employee: 'EMPLOYEE'
};
function parseEmployee(_ref12) {
  let {
    id,
    first_name,
    last_name,
    professional_email,
    company_employee_id,
    cgu_accepted,
    receive_newsletter,
    privacy_policy_accepted,
    role,
    can_declare_fmd,
    user,
    survey,
    partner,
    join_status
  } = _ref12;
  return new _models.Employee(id || -1, first_name, last_name, professional_email, company_employee_id, Boolean(cgu_accepted), Boolean(receive_newsletter), Boolean(privacy_policy_accepted), role && rolesMap[role] || 'employee', Boolean(can_declare_fmd), user ? {
    id: user.id,
    username: user.username,
    profilePicture: user.profile_picture ? `${_environment.defaultEnvironment.backendUrl}${user.profile_picture}` : undefined,
    subgroupsIds: user.subgroups?.map(_ref13 => {
      let {
        group_id
      } = _ref13;
      return group_id;
    }),
    joinedSites: user.joined_sites
  } : undefined, partner ? {
    id: partner.id,
    icon: partner.icon ? `${_environment.defaultEnvironment.backendUrl}${partner.icon}` : null,
    title: partner.title || ''
  } : null, survey || {}, join_status);
}
function parseEmployeeAndPartner(data) {
  const {
    partner
  } = data;
  return {
    employee: parseEmployee(data),
    partner: partner ? (0, _partner.parsePartner)({
      partner,
      is_default: true
    }) : null
  };
}
function parseCertificate(_ref14) {
  let {
    id,
    created,
    updated,
    start_date,
    end_date,
    amount_to_pay: compensation,
    count_days_total: daysCount,
    count_days_user_trace_journeys: validatedDaysCount,
    sum_distance_manually_declared_journeys,
    sum_distance_user_trace_journeys,
    count_manually_declared_journeys: manuallyDeclaredJourneysCount,
    authorized_manually_declared_journeys_ratio: maxManuallyDeclaredJourneysRatio,
    count_user_trace_journeys: journeysCount,
    attestation_validation_limit_day: validationLimitDay,
    attestation_file: url,
    employee,
    occurrences,
    commuting_trip_occurrences,
    geo_point_start,
    geo_point_start_title,
    geo_point_end,
    geo_point_end_title,
    is_temporary,
    is_valid
  } = _ref14;
  return {
    id: id || -1,
    created: created ? (0, _moment.default)(created) : undefined,
    updated: updated ? (0, _moment.default)(updated) : undefined,
    monthFirstDay: start_date ? (0, _moment.default)(start_date).startOf('month') : undefined,
    monthLastDay: end_date ? (0, _moment.default)(end_date).endOf('month') : undefined,
    compensation,
    validatedDaysCount,
    daysCount,
    validatedDistance: sum_distance_user_trace_journeys,
    totalDistance: typeof sum_distance_manually_declared_journeys === 'number' || typeof sum_distance_user_trace_journeys === 'number' ? (sum_distance_manually_declared_journeys || 0) + (sum_distance_user_trace_journeys || 0) : sum_distance_manually_declared_journeys,
    journeysCount,
    manuallyDeclaredJourneysCount,
    maxManuallyDeclaredJourneysRatio,
    validationLimitDay,
    url: url && url.indexOf('https') === -1 && url.indexOf('http') === -1 ? `${_app.AppService.environment.backendUrl}${url}` : url,
    employee: employee ? parseEmployee(employee) : undefined,
    occurrences: occurrences ? occurrences.map(data => (0, _user.parseUserReferenceTripOccurrence)(data)) : undefined,
    commutingTripOccurrences: commuting_trip_occurrences ? commuting_trip_occurrences.map(data => (0, _commutingTrip.parseCommutingTripOccurrence)(data)) : undefined,
    home: geo_point_start ? new _models.Place(-1, geo_point_start, geo_point_start_title ? {
      primaryText: geo_point_start_title
    } : undefined) : undefined,
    work: geo_point_end ? new _models.Place(-1, geo_point_end, geo_point_end_title ? {
      primaryText: geo_point_end_title
    } : undefined) : undefined,
    isValid: is_valid || false,
    isTemporary: is_temporary || false
  };
}