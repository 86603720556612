"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.traceGroundTruths = exports.TraceProblem = exports.Trace = void 0;
var _stats = require("./stats");
class Trace {
  constructor(id, title, startDate, endDate, geometry, distance, duration, verticalGain, averageSpeed, speeds, elevations, computed, previewUrl, hasProblems, problems, source, commutingStatus, challengesIds, status, referenceTripOccurrences, badges, isPotentiallyBike) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.geometry = geometry;
    this.distance = distance;
    this.duration = duration;
    this.verticalGain = verticalGain;
    this.averageSpeed = averageSpeed;
    this.speeds = speeds;
    this.elevations = elevations;
    this.computed = computed;
    this.previewUrl = previewUrl;
    this.hasProblems = hasProblems;
    this.problems = problems;
    this.source = source;
    this.commutingStatus = commutingStatus;
    this.challengesIds = challengesIds;
    this.status = status;
    this.referenceTripOccurrences = referenceTripOccurrences;
    this.badges = badges;
    this.isPotentiallyBike = isPotentiallyBike;
  }
  get asStats() {
    return {
      [_stats.StatsTypes.Distance]: this.distance,
      [_stats.StatsTypes.Duration]: this.duration,
      [_stats.StatsTypes.VerticalGain]: this.verticalGain,
      [_stats.StatsTypes.AverageSpeed]: this.averageSpeed
    };
  }
  clone() {
    return new Trace(this.id, this.title, this.startDate, this.endDate, this.geometry, this.distance, this.duration, this.verticalGain, this.averageSpeed, this.speeds, this.elevations, this.computed, this.previewUrl, this.hasProblems, this.problems, this.source, this.commutingStatus, this.challengesIds, this.status, this.referenceTripOccurrences, this.badges, this.isPotentiallyBike);
  }
}
exports.Trace = Trace;
class TraceProblem {
  constructor(problemType, fixDatetime) {
    this.problemType = problemType;
    this.fixDatetime = fixDatetime;
  }
}
exports.TraceProblem = TraceProblem;
const traceGroundTruths = exports.traceGroundTruths = ['BICYCLE', 'CAR', 'PEDESTRIAN', 'TRAIN', 'PUBLIC_TRANSPORT'];