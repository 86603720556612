import { useAmplitudeTracker } from '@geovelo-frontends/commons';
import { ReactNode, Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Navigate, Route, RouteProps, useLocation, useSearchParams } from 'react-router-dom';

import Layout from '../../layouts/default';
import WebviewLayout from '../../layouts/webview';
import UpgradeCommunityPage from '../../pages/upgrade-community';
import { AppContext } from '../context';

const AddCompanyDataPage = lazy(() => import('../../pages/add-company/company-data'));
const AddCompanyEmployeesPage = lazy(() => import('../../pages/add-company/employees'));
const AddCompanyPersonalDataPage = lazy(() => import('../../pages/add-company/personal-data'));
const AddCompanySitesPage = lazy(() => import('../../pages/add-company/sites'));
const AdminPage = lazy(() => import('../../pages/admin'));
const AddMembersPage = lazy(() => import('../../pages/admin/add-members'));
const AddSitesPage = lazy(() => import('../../pages/admin/add-sites'));
const AdminDataTab = lazy(() => import('../../pages/admin/data'));
const AdminEmployeesTab = lazy(() => import('../../pages/admin/employees'));
const AdminOffersTab = lazy(() => import('../../pages/admin/offers'));
const AdminSitesTab = lazy(() => import('../../pages/admin/sites'));
const AdminTeamsTab = lazy(() => import('../../pages/admin/teams'));
const ChallengesPage = lazy(() => import('../../pages/challenges'));
const ChallengeDetailsPage = lazy(() => import('../../pages/challenge-details'));
const ChallengeFormPage = lazy(() => import('../../pages/challenge-form'));
const ConnectPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/connect'),
);
const CyclabilityPage = lazy(() => import('../../pages/cyclability'));
const CyclingPotentialPage = lazy(() => import('../../pages/cycling-potential'));
const FMDPage = lazy(() => import('../../pages/fmd'));
const FMDCertificatesPage = lazy(() => import('../../pages/fmd-certificates'));
const FMDConfigPage = lazy(() => import('../../pages/fmd-config'));
const FMDEmployeePage = lazy(() => import('../../pages/fmd-employee'));
const ForgotPasswordPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/forgot-password'),
);
const GetStartedPage = lazy(() => import('../../pages/get-started'));
const HomePage = lazy(() => import('../../pages/home'));
const JoinCommunityPage = lazy(() => import('../../pages/join-community'));
const JourneyFormPage = lazy(() => import('../../pages/journey-form'));
const LeaderboardByTeamsPage = lazy(() => import('../../pages/leaderboard-by-teams'));
const IndividualLeaderboardPage = lazy(() => import('../../pages/leaderboard-individual'));
const LeaderboardsPage = lazy(() => import('../../pages/leaderboards'));
const LoginPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/login'),
);
const MobilitySurveyPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/mobility-survey'),
);
const MobilitySurveyAdminPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/mobility-survey-admin'),
);
const NewPostPage = lazy(() => import('../../pages/new-post'));
const NewsPage = lazy(() => import('../../pages/news'));
const NotLinkedPage = lazy(() => import('../../pages/not-linked'));
const OnboardingAddressesPage = lazy(() => import('../../pages/onboarding/addresses'));
const OnboardingPersonalDataPage = lazy(() => import('../../pages/onboarding/personal-data'));
const PDFViewerPage = lazy(() => import('../../pages/pdf-viewer'));
const PostUpdatePage = lazy(() => import('../../pages/post-update'));
const ProfilePage = lazy(() => import('../../pages/profile'));
const RegisterPage = lazy<(props: { isSubscription?: boolean }) => JSX.Element>(
  () => import('../../pages/register'),
);
const SearchCompanyPage = lazy(() => import('../../pages/search-company'));
const StatsPage = lazy(() => import('../../pages/stats'));
const StatsBySubgroupsPage = lazy(() => import('../../pages/stats-by-subgroups'));
const SubscriptionPage = lazy(() => import('../../pages/subscription'));
const ToolsCompaniesListPage = lazy(() => import('../../pages/tools/companies-list'));
const ToolsUsersListPage = lazy(() => import('../../pages/tools/users-list'));
const TryForFreePage = lazy(() => import('../../pages/try-for-free'));
const AddCompanyWebviewPage = lazy(() => import('../../pages/webviews/add-company'));
const AddCompanyWebviewDonePage = lazy(() => import('../../pages/webviews/add-company/done'));
const FMDWebviewPage = lazy(() => import('../../pages/webviews/fmd'));
const OnboardingWebviewPage = lazy(() => import('../../pages/webviews/onboarding'));
const OnboardingWebviewDonePage = lazy(() => import('../../pages/webviews/onboarding/done'));
const WelcomePage = lazy(() => import('../../pages/welcome'));

export type TPaths =
  | '/'
  | 'subscription'
  | 'subscription/login'
  | 'subscription/forgot-password'
  | 'subscription/register'
  | 'subscription/connect'
  | 'subscription/upgrade-community'
  | 'subscription/personal-data'
  | 'subscription/company-data'
  | 'subscription/sites'
  | 'subscription/employees'
  | 'join/:code'
  | 'try'
  | 'welcome'
  | 'welcome/login'
  | 'welcome/forgot-password'
  | 'welcome/register'
  | 'welcome/search-company'
  | 'welcome/not-linked'
  | 'onboarding/personal-data'
  | 'onboarding/addresses'
  | 'cycling-potential'
  | 'mobility-survey/:code'
  | 'webviews'
  | 'pdf-viewer';

export const defaultPrivatePath: TPaths = '/';
export const defaultGuestPath: `/${TPaths}` = '/welcome/login';

const routes: Array<
  Omit<RouteProps, 'path' | 'index' | 'lazy'> & {
    isGuest?: boolean;
    isPrivate?: boolean;
    path?: TPaths | '*';
  }
> = [
  {
    path: 'subscription',
    element: (
      <RouteElement pageName="subscription_1_welcome">
        <SubscriptionPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'subscription/login',
    element: (
      <RouteElement pageName="subscription_2_login">
        <LoginPage isSubscription />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'subscription/forgot-password',
    element: (
      <RouteElement pageName="subscription_2_forgot_password">
        <ForgotPasswordPage isSubscription />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'subscription/register',
    element: (
      <RouteElement pageName="subscription_2_register">
        <RegisterPage isSubscription />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'subscription/connect',
    element: (
      <RouteElement pageName="subscription_2_connect">
        <ConnectPage isSubscription />
      </RouteElement>
    ),
  },
  {
    path: 'subscription/upgrade-community',
    element: (
      <RouteElement pageName="subscription_2_2_upgrade_community">
        <UpgradeCommunityPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'subscription/personal-data',
    element: (
      <RouteElement pageName="subscription_3_personal_data">
        <AddCompanyPersonalDataPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'subscription/company-data',
    element: (
      <RouteElement pageName="subscription_4_company_data">
        <AddCompanyDataPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'subscription/sites',
    element: (
      <RouteElement pageName="subscription_6_sites">
        <AddCompanySitesPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'subscription/employees',
    element: (
      <RouteElement pageName="subscription_5_employees">
        <AddCompanyEmployeesPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'join/:code',
    element: (
      <RouteElement pageName="join_community">
        <JoinCommunityPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'try',
    element: (
      <RouteElement pageName="try_for_free_welcome">
        <TryForFreePage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome',
    element: (
      <RouteElement pageName="employee_welcome">
        <WelcomePage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome/login',
    element: (
      <RouteElement pageName="employee_login">
        <LoginPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome/forgot-password',
    element: (
      <RouteElement pageName="employee_forgot_password">
        <ForgotPasswordPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome/register',
    element: (
      <RouteElement pageName="employee_register">
        <RegisterPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome/search-company',
    element: (
      <RouteElement pageName="employee_search_company">
        <SearchCompanyPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'welcome/not-linked',
    element: (
      <RouteElement pageName="employee_not_linked">
        <NotLinkedPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'onboarding/personal-data',
    element: (
      <RouteElement pageName="onboarding_1_personal_data">
        <OnboardingPersonalDataPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'onboarding/addresses',
    element: (
      <RouteElement pageName="onboarding_2_address">
        <OnboardingAddressesPage />
      </RouteElement>
    ),
    isPrivate: true,
  },
  {
    path: 'cycling-potential',
    element: (
      <RouteElement pageName="cycling_potential">
        <CyclingPotentialPage />
      </RouteElement>
    ),
  },
  {
    path: 'mobility-survey/:code',
    element: (
      <RouteElement pageName="mobility_survey">
        <MobilitySurveyPage />
      </RouteElement>
    ),
    isGuest: true,
  },
  {
    path: 'pdf-viewer',
    element: (
      <RouteElement pageName="pdf_viewer">
        <PDFViewerPage />
      </RouteElement>
    ),
  },
  {
    path: 'webviews',
    element: <WebviewLayout />,
    children: (
      <>
        <Route
          element={
            <RouteElement pageName="add_company_webview_done">
              <AddCompanyWebviewDonePage />
            </RouteElement>
          }
          path="add-company/done"
        />
        <Route
          element={
            <RouteElement pageName="add_company_webview">
              <AddCompanyWebviewPage />
            </RouteElement>
          }
          path="add-company"
        />
        <Route
          element={
            <RouteElement pageName="animation_hub_webview">
              <FMDWebviewPage />
            </RouteElement>
          }
          path="bicycle-compensation"
        />
        <Route
          element={
            <RouteElement pageName="onboarding_webview_done">
              <OnboardingWebviewDonePage />
            </RouteElement>
          }
          path="onboarding/done"
        />
        <Route
          element={
            <RouteElement pageName="onboarding_webview">
              <OnboardingWebviewPage />
            </RouteElement>
          }
          path="onboarding"
        />
      </>
    ),
  },
  {
    path: '/',
    isPrivate: true,
    element: <Layout />,
    children: (
      <>
        <Route
          index
          element={
            <RouteElement pageName="home_page">
              <HomePage />
            </RouteElement>
          }
        />
        <Route
          element={
            <RouteElement pageName="get_started">
              <GetStartedPage />
            </RouteElement>
          }
          path="get-started"
        />
        <Route
          element={
            <RouteElement pageName="challenges_hub">
              <ChallengesPage />
            </RouteElement>
          }
          path="challenges"
        />
        <Route
          element={
            <RouteElement pageName="leaderboards_hub">
              <LeaderboardsPage />
            </RouteElement>
          }
          path="leaderboards"
        />
        <Route
          element={
            <RouteElement pageName="leaderboard_individual">
              <IndividualLeaderboardPage />
            </RouteElement>
          }
          path="leaderboards/cyclists"
        />
        <Route
          element={
            <RouteElement pageName="leaderboard_by_teams">
              <LeaderboardByTeamsPage type="teams" />
            </RouteElement>
          }
          path="leaderboards/teams"
        />
        <Route
          element={
            <RouteElement pageName="leaderboard_by_sites">
              <LeaderboardByTeamsPage type="sites" />
            </RouteElement>
          }
          path="leaderboards/sites"
        />
        <Route
          element={
            <RouteElement pageName="statistics_hub">
              <StatsPage />
            </RouteElement>
          }
          path="stats"
        />
        <Route
          element={
            <RouteElement pageName="statistics_by_sites">
              <StatsBySubgroupsPage subgroupType="companySite" />
            </RouteElement>
          }
          path="stats/sites"
        />
        <Route
          element={
            <RouteElement pageName="statistics_by_teams">
              <StatsBySubgroupsPage subgroupType="companyTeam" />
            </RouteElement>
          }
          path="stats/teams"
        />
        <Route
          element={
            <RouteElement pageName="news_list">
              <NewsPage />
            </RouteElement>
          }
          path="news"
        />
        <Route
          element={
            <RouteElement pageName="news_creation">
              <NewPostPage />
            </RouteElement>
          }
          path="new-post"
        />
        <Route
          element={
            <RouteElement pageName="news_update">
              <PostUpdatePage />
            </RouteElement>
          }
          path="post/:id/update"
        />
        <Route
          element={
            <RouteElement pageName="challenge_detail">
              <ChallengeDetailsPage />
            </RouteElement>
          }
          path="challenges/:id"
        />
        <Route
          element={
            <RouteElement pageName="challenge_update">
              <ChallengeFormPage />
            </RouteElement>
          }
          path="challenges/:id/update"
        />
        <Route
          element={
            <RouteElement pageName="challenge_creation">
              <ChallengeFormPage />
            </RouteElement>
          }
          path="new-challenge"
        />
        <Route
          element={
            <RouteElement pageName="indemnity_calendar">
              <FMDPage />
            </RouteElement>
          }
          path="bicycle-compensation"
        />
        <Route
          element={
            <RouteElement pageName="indemnity_employee_certificates">
              <FMDCertificatesPage />
            </RouteElement>
          }
          path="bicycle-compensation/certificates"
        />
        <Route
          element={
            <RouteElement pageName="indemnity_parameters">
              <FMDConfigPage />
            </RouteElement>
          }
          path="bicycle-compensation/config"
        />
        <Route
          element={
            <RouteElement pageName="indemnity_employee_detail">
              <FMDEmployeePage />
            </RouteElement>
          }
          path="bicycle-compensation/employees/:id"
        />
        <Route
          element={
            <RouteElement pageName="accessibility_hub">
              <CyclabilityPage />
            </RouteElement>
          }
          path="accessibility"
        />
        <Route
          element={
            <RouteElement pageName="profile">
              <ProfilePage />
            </RouteElement>
          }
          path="profile"
        />
        <Route
          element={
            <RouteElement pageName="home_work_journey_update">
              <JourneyFormPage />
            </RouteElement>
          }
          path="profile/journeys/:id"
        />
        <Route
          element={
            <RouteElement pageName="home_work_journey_creation">
              <JourneyFormPage />
            </RouteElement>
          }
          path="profile/new-journey"
        />
        <Route
          element={
            <RouteElement>
              <AddMembersPage />
            </RouteElement>
          }
          path="admin/add-members"
        />
        <Route
          element={
            <RouteElement>
              <AddSitesPage />
            </RouteElement>
          }
          path="admin/add-sites"
        />
        <Route
          element={
            <RouteElement>
              <AdminPage />
            </RouteElement>
          }
          path="admin"
        >
          <Route element={<AdminDataTab />} path="data" />
          <Route element={<AdminSitesTab />} path="sites" />
          <Route element={<AdminEmployeesTab />} path="employees" />
          <Route element={<AdminTeamsTab />} path="teams" />
          <Route element={<AdminOffersTab />} path="offers" />
        </Route>
        <Route
          element={
            <RouteElement pageName="mobility_survey_admin">
              <MobilitySurveyAdminPage />
            </RouteElement>
          }
          path="mobility-survey"
        />
        <Route element={<Navigate replace to="/tools/companies" />} path="new-contract" />
        <Route
          element={
            <RouteElement pageName="tools_companies_list">
              <ToolsCompaniesListPage />
            </RouteElement>
          }
          path="tools/companies"
        />
        <Route
          element={
            <RouteElement pageName="tools_users_list">
              <ToolsUsersListPage />
            </RouteElement>
          }
          path="tools/users"
        />
      </>
    ),
  },
];

function RouteElement({
  pageName,
  children,
}: {
  children: ReactNode;
  pageName?: string;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner, contract },
    user: { current: currentUser, employee },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    const utm_source = searchParams.get('utm_source');
    if (pageName)
      trackEvent('Page Visited', {
        pathname,
        page_name: pageName,
        employee_id: employee?.id,
        partner_id: currentPartner?.id,
        partner_code: currentPartner?.code,
        user_type: employee?.role || 'guest',
        contract_type: contract?.contractTemplate.code,
        utm_source,
      });
  }, [initialized, currentUser, employee, currentPartner, pageName]);

  return <Suspense fallback={<></>}>{children}</Suspense>;
}

export default routes;
