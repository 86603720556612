import { CompanyIcon } from '@geovelo-frontends/commons';
import { KeyboardArrowUp, Menu } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';

import { AppContext } from '../../app/context';

import CompanyMenu from './company-menu';

function Header({ handleDrawerToggle }: { handleDrawerToggle: () => void }): JSX.Element {
  const [companyMenuAnchorEl, setCompanyMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    partner: { current: currentPartner },
    user: { employees },
  } = useContext(AppContext);

  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        sx={({ zIndex }) => ({
          backgroundColor: '#fff',
          color: 'inherit',
          display: { xs: 'block', sm: 'none' },
          zIndex: zIndex.drawer + 1,
        })}
      >
        <Toolbar>
          <Box alignItems="center" display="flex" gap={1}>
            {currentPartner?.icon ? (
              <Avatar src={currentPartner.icon} />
            ) : (
              <Avatar sx={({ palette }) => ({ bgcolor: palette.primary.main })}>
                <CompanyIcon />
              </Avatar>
            )}
            <Typography noWrap fontWeight={700} title={currentPartner?.title}>
              {currentPartner?.title || <Skeleton variant="text" width={100} />}
            </Typography>
            {employees && employees.length > 1 && (
              <Tooltip placement="right" title="Sélectionner l'entreprise">
                <IconButton
                  onClick={({ currentTarget }) => setCompanyMenuAnchorEl(currentTarget)}
                  size="small"
                >
                  <KeyboardArrowUp fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box flexGrow={1} />
          <IconButton
            aria-label="open drawer"
            color="default"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <CompanyMenu
        companyMenuAnchorEl={companyMenuAnchorEl}
        setCompanyMenuAnchorEl={setCompanyMenuAnchorEl}
      />
    </>
  );
}

export default Header;
