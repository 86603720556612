import { useFileSaver } from '@geovelo-frontends/commons';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { AppContext } from '../../app/context';
import congratsIllustration from '../../assets/img/congrats-illus.svg';
import {
  ArrowOutward,
  Button,
  CommunityFlyerDocument,
  Dialog,
  DownloadIcon,
} from '../../components';
import { environment } from '../../environment';

function CongratsDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const {
    partner: { current: currentPartner, invitationCode },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadBlob } = useFileSaver();

  async function generatePDF() {
    if (!qrCodeRef.current || !currentPartner) return;

    setGeneratingPDF(true);

    try {
      const canvas = await html2canvas(qrCodeRef.current, {
        scale: 3,
        width: qrCodeRef.current.clientWidth,
        height: qrCodeRef.current.clientHeight,
        useCORS: true,
      });

      const blob = await pdf(
        <CommunityFlyerDocument
          currentPartner={currentPartner}
          qrCode={canvas.toDataURL('image/png', 1.0)}
        />,
      ).toBlob();

      downloadBlob('flyer-geovelo-entreprise.pdf', blob);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    }

    setGeneratingPDF(false);
  }

  if (!currentPartner) return <></>;

  const invitationLink = `${environment.enterpriseUrl}join/${invitationCode}`;

  return (
    <>
      <Dialog disableBackgroundClick fullWidth maxWidth="sm" {...props}>
        <Box display="flex" flexDirection="column" gap={3}>
          <img src={congratsIllustration} width={160} />
          <Typography fontSize="1.25rem" fontWeight={600}>
            Félicitations, tout est prêt ! 🎉
          </Typography>
          <Typography variant="body2">
            Vous avez complété toutes les étapes, il ne reste plus qu'à motiver vos équipes à rouler
            grâce à notre flyer ou notre kit de communication
          </Typography>
          <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
            <Button
              color="primary"
              component="a"
              href={environment.driveLinks.communicationKitUrl}
              rel="noreferrer"
              startIcon={<ArrowOutward />}
              target="_blank"
              variant="outlined"
            >
              Voir le kit de communication
            </Button>
            <Button
              color="primary"
              disabled={generatingPDF}
              onClick={() => generatePDF()}
              startIcon={
                generatingPDF ? (
                  <CircularProgress color="inherit" size={16} thickness={4} />
                ) : (
                  <DownloadIcon />
                )
              }
              variant="contained"
            >
              {t('companies.actions.download_flyer')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      {props.open && (
        <Box
          height={128}
          left={0}
          position="absolute"
          ref={qrCodeRef}
          top={0}
          width={128}
          zIndex={-1}
        >
          <QRCode
            fgColor="#212121"
            size={128}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={invitationLink}
            viewBox={`0 0 128 128`}
          />
        </Box>
      )}
    </>
  );
}

export default CongratsDialog;
