import { CompanyIcon } from '@geovelo-frontends/commons';
import {
  Avatar,
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../../app/context';

function CompanyMenu({
  companyMenuAnchorEl,
  setCompanyMenuAnchorEl,
}: {
  companyMenuAnchorEl: HTMLButtonElement | null;
  setCompanyMenuAnchorEl: (el: HTMLButtonElement | null) => void;
}): JSX.Element {
  const {
    user: { employees },
    actions: { selectCompany },
  } = useContext(AppContext);

  return (
    <Popover
      anchorEl={companyMenuAnchorEl}
      onClose={() => setCompanyMenuAnchorEl(null)}
      open={Boolean(companyMenuAnchorEl)}
      slotProps={{
        root: { sx: { '.MuiBackdrop-root': { bgcolor: 'initial' } } },
        paper: { sx: { borderRadius: 2, minWidth: 300 } },
      }}
      transformOrigin={{ horizontal: 300, vertical: -50 }}
    >
      <Box display="flex" flexDirection="column" maxWidth={300}>
        <Box paddingTop={2} paddingX={2}>
          <Typography color="textSecondary" textTransform="uppercase" variant="caption">
            Sélectionner l'entreprise
          </Typography>
        </Box>
        <List>
          {employees?.map(({ employee, partner }) => (
            <ListItemButton
              key={employee.id}
              onClick={() => {
                setCompanyMenuAnchorEl(null);
                selectCompany(employee, partner);

                try {
                  localStorage.setItem('company_id', `${partner.id}`);
                } catch {
                  console.error('localStorage access is denied');
                }
              }}
            >
              <ListItemAvatar>
                {partner.icon ? (
                  <Avatar src={partner.icon} sx={{ height: 32, width: 32 }} />
                ) : (
                  <Avatar
                    sx={({ palette }) => ({
                      bgcolor: palette.primary.main,
                      height: 32,
                      width: 32,
                    })}
                  >
                    <CompanyIcon fontSize="small" />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={partner.title} primaryTypographyProps={{ noWrap: true }} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Popover>
  );
}

export default CompanyMenu;
