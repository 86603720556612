"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IsochroneService = void 0;
var _models = require("../models");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
class IsochroneService {
  static async computeIsochrones(_ref) {
    let {
      durations,
      locations,
      profile,
      eBikeEnabled,
      partner
    } = _ref;
    try {
      const data = await _http.HttpService.post('v2', '/isochrones', [{
        key: 'profile',
        value: _cyclingProfile.cyclingProfilesMap[profile || 'direct']
      }, {
        key: 'ebike',
        value: Boolean(eBikeEnabled)
      }], [], JSON.stringify({
        durations,
        locations
      }), {
        partner: partner?.code
      });
      const isochrones = [];
      durations.forEach(duration => {
        isochrones.push(new _models.Isochrone(duration, {
          type: 'MultiPolygon',
          coordinates: data.filter(_ref2 => {
            let {
              duration: _duration
            } = _ref2;
            return _duration === duration;
          }).reduce((res, _ref3) => {
            let {
              geometry: {
                coordinates
              }
            } = _ref3;
            res.push([coordinates]);
            return res;
          }, [])
        }));
      });
      return isochrones;
    } catch (err) {
      console.error('[IsochroneService][computeIsochrones]', err);
      throw new Error('isochrones computing failed');
    }
  }
  static async computeGraphHopperIsochrones(_ref4) {
    let {
      center,
      profile,
      duration,
      buckets
    } = _ref4;
    try {
      const queryParams = [{
        key: 'point',
        value: center.coordinates.slice().reverse().join(',')
      }, {
        key: 'profile',
        value: profile === 'safe' ? 'bicycle_safe' : 'bicycle_direct'
      }, {
        key: 'time_limit',
        value: duration
      }, {
        key: 'buckets',
        value: buckets
      }];
      const {
        polygons
      } = await _http.HttpService.get('v1', '/graphhopper/isochrone', queryParams);
      return polygons.map(_ref5 => {
        let {
          geometry: {
            coordinates
          },
          properties: {
            bucket
          }
        } = _ref5;
        return new _models.Isochrone(duration / (buckets - bucket), {
          type: 'MultiPolygon',
          coordinates: [coordinates]
        });
      });
    } catch (err) {
      console.error('[IsochroneService][computeGraphHopperIsochrones]', err);
      throw new Error('isochrones computing failed');
    }
  }
  static async getIsochronesFile(_ref6) {
    let {
      durations,
      locations,
      profile,
      eBikeEnabled
    } = _ref6;
    try {
      const data = await _http.HttpService.post('v2', '/v2', [{
        key: 'content-type',
        value: 'shapefile'
      }, {
        key: 'profile',
        value: _cyclingProfile.cyclingProfilesMap[profile || 'direct']
      }, {
        key: 'ebike',
        value: Boolean(eBikeEnabled)
      }], [], JSON.stringify({
        durations,
        locations
      }), {
        responseType: 'blob'
      });
      return data;
    } catch (err) {
      console.error('[IsochroneService][downloadIsochrones]', err);
      throw err;
    }
  }
}
exports.IsochroneService = IsochroneService;