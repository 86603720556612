import { useFileSaver } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import { useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { AppContext } from '../../app/context';
import {
  Button,
  CommunityFlyerDocument,
  Dialog,
  DownloadIcon,
  NewInfoCard,
} from '../../components';
import { environment } from '../../environment';

function FirstMembersAddedDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const {
    partner: { current: currentPartner, invitationCode },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadBlob } = useFileSaver();

  async function generatePDF() {
    if (!qrCodeRef.current || !currentPartner) return;

    setLoading(true);

    try {
      const canvas = await html2canvas(qrCodeRef.current, {
        scale: 3,
        width: qrCodeRef.current.clientWidth,
        height: qrCodeRef.current.clientHeight,
        useCORS: true,
      });

      const blob = await pdf(
        <CommunityFlyerDocument
          currentPartner={currentPartner}
          qrCode={canvas.toDataURL('image/png', 1.0)}
        />,
      ).toBlob();

      downloadBlob('flyer-geovelo-entreprise.pdf', blob);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    }

    setLoading(false);
  }

  if (!invitationCode) return <></>;

  const invitationLink = `${environment.enterpriseUrl}join/${invitationCode}`;

  return (
    <>
      <Dialog fullWidth maxWidth="sm" {...props}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography fontSize="1.25rem" fontWeight={600}>
            {t('companies.pages.admin.company.employees.congratulations_dialog.title')}
          </Typography>
          <Typography variant="body2">
            {t('companies.pages.admin.company.employees.congratulations_dialog.description', {
              partnerTitle: currentPartner?.title,
            })}
          </Typography>
          <NewInfoCard
            action={
              <Box alignItems="flex-start" display="flex" flexDirection="column" gap={0.5}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => generatePDF()}
                  size="medium"
                  startIcon={
                    loading ? (
                      <CircularProgress color="inherit" size={16} thickness={4} />
                    ) : (
                      <DownloadIcon />
                    )
                  }
                  variant="text"
                >
                  {t('companies.actions.download_flyer')}
                </Button>
                <Button
                  color="primary"
                  component="a"
                  href="https://docs.google.com/presentation/d/1XP-q_arXWnSjWYoJvjqZF7MI-kJqAfpX/edit?usp=sharing&ouid=111390925796268393394&rtpof=true&sd=true"
                  rel="noreferrer"
                  size="medium"
                  startIcon={<DownloadIcon />}
                  target="_blank"
                  variant="text"
                >
                  {t(
                    'companies.pages.admin.company.employees.congratulations_dialog.actions.see_collaborators_guide',
                  )}
                </Button>
              </Box>
            }
            description={
              <Trans
                components={[<b key={0} />]}
                i18nKey="companies.pages.admin.company.employees.congratulations_dialog.download_flyer"
              />
            }
            Icon={InfoOutlined}
            size="small"
            variant="success"
          />
          <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
            <Button
              color="primary"
              disabled={loading}
              onClick={() => props.onClose()}
              variant="contained"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
      {props.open && (
        <Box
          height={128}
          left={0}
          position="absolute"
          ref={qrCodeRef}
          top={0}
          width={128}
          zIndex={-1}
        >
          <QRCode
            fgColor="#212121"
            size={128}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={invitationLink}
            viewBox={`0 0 128 128`}
          />
        </Box>
      )}
    </>
  );
}

export default FirstMembersAddedDialog;
